'use client';

import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';

import { cn } from 'lib/utils';

interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  checkBoxSize?: {
    checkboxSize: number;
    checkSize: number;
  };
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, checkBoxSize, ...props }, ref) => {
  return (
    <CheckboxPrimitive.Root
      ref={ref}
      style={{
        height: `${checkBoxSize?.checkboxSize ? checkBoxSize.checkboxSize : 16}px`,
        width: `${checkBoxSize?.checkboxSize ? checkBoxSize.checkboxSize : 16}px`,
        borderRadius: '0.125rem',
      }}
      className={cn(
        'peer shrink-0 rounded-sm border border-white ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn(
          'flex items-center h-full w-full justify-center text-current'
        )}
      >
        <Check
          className={`h-[${checkBoxSize?.checkSize ? checkBoxSize?.checkSize : 10}px] w-[${checkBoxSize?.checkSize ? checkBoxSize.checkSize : 10}px]`}
        />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
