import { useCreateMapFromOverlay } from 'api/mutations';
import {
  CustomEmojiPicker,
  ExtendedEmojiDataType,
} from 'components/emojiPicker/CustomEmojiPicker';
import SimpleTextInput from 'CoreComponents/SimpleTextInput/SimpleTextInput';
import FolderMapCreation from 'PlasmicComponents/FolderMapCreation';
import { useEffect, useRef, useState } from 'react';
import { dashboardStore, toggleNewMapModal } from 'utils/stores/dashboardStore';
import { addMapBeingCreated } from 'utils/stores/generalStore';
import { folderMapCreationFlow } from 'utils/stores/types';

import { v4 as uuidv4 } from 'uuid';

import EmojiAndIconSelectorWrapper from 'components/EmojiAndIconSelector/EmojiAndIconSelectorWrapper';
import { useHotkeys } from '@mantine/hooks';
import { MapUtils } from 'CoreComponents/maps/mapUtils';

const NewMapAndFolder = () => {
  const [mapCreationState, setmapCreationState] =
    useState<folderMapCreationFlow>('titleInput');

  const inputRef = useRef(null);
  const confirmButtonRef = useRef(null);
  const showMoreRef = useRef(null);

  const [title, setTitle] = useState('');
  const [emoji, setEmoji] = useState(null);

  const { breadcrumbs } = dashboardStore;

  const updatedBreadcrumbs = [
    { name: 'Root Folder', folderId: ':root' },
    ...breadcrumbs,
  ];

  const currentFolder = updatedBreadcrumbs.pop();
  const folderId =
    currentFolder.folderId === ':root' ? null : currentFolder.folderId;

  const [selectedIcon, setSelectedIcon] =
    useState<ExtendedEmojiDataType | null>(null);

  const [showIconAndEmojiSelector, setShowIconAndEmojiSelector] =
    useState(false);

  const { mutate: addMap } = useCreateMapFromOverlay(true, folderId);

  const handleInputChange = e => {
    setTitle(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      const focusableIcon = document.querySelector('.icon') as HTMLElement;
      if (focusableIcon) {
        focusableIcon.focus();
      }
    }, 200);
  }, [mapCreationState]);

  const handleKeyDown = event => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      console.log('button hit');
      updateCreationState('map');
    }
  };

  const handleAddMap = emojiData => {
    const newMapId = uuidv4();
    const mapDTO = { ...(new MapUtils.Map(newMapId, title) as any) };
    addMapBeingCreated(newMapId);

    if (emojiData === null) {
      mapDTO.icon = { name: '🗺️', type: 'emoji', color: '' };
    } else {
      if (emojiData.iconType === 'emoji')
        mapDTO.icon = { name: emojiData.icon, color: '', type: 'emoji' };
      if (emojiData.iconType === 'icon')
        mapDTO.icon = {
          name: emojiData.icon,
          color: emojiData.color,
          type: 'icon',
        };
    }
    if (folderId) mapDTO.folderId = folderId;

    addMap(mapDTO);

    setmapCreationState('titleInput');
    setTitle('');
    setEmoji('');
    toggleNewMapModal();
  };

  const updateCreationState = (item: 'folder' | 'map') => {
    if (item === 'map') {
      if (mapCreationState === 'createMapButton') {
        if (showIconAndEmojiSelector) setShowIconAndEmojiSelector(false);

        setmapCreationState('titleInput');
      } else if (mapCreationState === 'titleInput') {
        if (title === '') return;
        setmapCreationState('iconOrEmojiSelection');
      } else if (mapCreationState === 'iconOrEmojiSelection') {
        createFolderMap(emoji);
        setmapCreationState('createMapButton');
      }
    }
  };

  const createFolderMap = (emojiData?: ExtendedEmojiDataType) => {
    console.log(mapCreationState);

    if (mapCreationState === 'iconOrEmojiSelection') {
      handleAddMap(emojiData);
      return;
    }

    setEmoji(emojiData);
  };

  const handleSelectIcon = (icon: {
    type: string;
    name: string;
    color: string;
  }) => {
    setSelectedIcon({
      iconType: icon.type,
      icon: icon.name,
      emoji: '',
      unified: '',
      isCustom: true,
      names: [],
      activeSkinTone: null,
      getImageUrl: null,
      imageUrl: '',
      unifiedWithoutSkinTone: '',
      color: icon.color,
    });
  };

  useEffect(() => {
    if (selectedIcon) {
      createFolderMap(selectedIcon);
    }
  }, [selectedIcon]);

  useHotkeys([
    [
      'Escape',
      e => {
        e.stopPropagation();
        e.preventDefault();
        if (mapCreationState !== 'titleInput') {
          if (showIconAndEmojiSelector) {
            setShowIconAndEmojiSelector(!showIconAndEmojiSelector);
            (document.querySelector('#selectMore') as HTMLElement).focus();
            return;
          }

          setTitle('');
          toggleNewMapModal();
          return;
        }
        toggleNewMapModal();
      },
    ],
  ]);

  return (
    <div>
      <FolderMapCreation
        creationFlow={mapCreationState}
        emojiPickerSlot={
          <div style={{ zIndex: '20' }}>
            <CustomEmojiPicker
              setEmoji={setEmoji}
              onReactionSelect={() => updateCreationState('map')}
              isCreatingMap={true}
            />
          </div>
        }
        titleCreationTextInputSlot={
          <SimpleTextInput
            ref={inputRef}
            id="folderInput"
            placeholder="Enter Map Title"
            onChange={handleInputChange}
            autoFocus={true}
            autoComplete="off"
            value={title}
            onKeyDown={handleKeyDown}
            // className="text-white focus:ring-white focus:border-white focus:shadow-outline border-none"
          />
        }
        //@ts-ignore
        confirmButton={{
          ref: confirmButtonRef,
          onClick: () => updateCreationState('map'),
          onKeyDown: e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.key === 'Enter') updateCreationState('map');
          },
        }}
        showMoreButton={{
          ref: showMoreRef,
          id: 'selectMore',
          onClick: () => setShowIconAndEmojiSelector(!showIconAndEmojiSelector),
          onKeyDown: e => {
            if (e.key === 'Enter')
              setShowIconAndEmojiSelector(!showIconAndEmojiSelector);
          },
        }}
      />

      {showIconAndEmojiSelector && (
        <div className="mt-2">
          <EmojiAndIconSelectorWrapper onIconSelect={handleSelectIcon} />
        </div>
      )}
    </div>
  );
};

export default NewMapAndFolder;
