import DropdownItem from 'PlasmicComponents/DropdownItem';
import DropdownItemGroup from 'PlasmicComponents/DropdownItemGroupDup';
import { useGetUserFolderStructure } from 'api/queries';
import FolderMapIconRenderer from 'components/folderMapIconRenderer/FolderMapIconRenderer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collectQuickAccessMaps } from 'utils/helpers/getRecent';
import { dashboardStore } from 'utils/stores/dashboardStore';
import { FolderData, FolderTree, MapData } from 'utils/stores/types';
import { FolderStructure } from 'views/dashboard/DashboardView';

interface QuickAccessMapItemProps {
  map: MapData;
  isInSidebar?: boolean;
  level?: number;
  onSelect?: (id: string, event: React.MouseEvent) => void;
  handleClick?: () => void;
  isQuickAccessModal?: boolean;
  isHighlighted?: boolean;
}

interface QuickAccessFolderItemProps {
  folder: FolderData;
  level?: number;
  parentLevel?: number;
  expandedFolderGroups: string[];
  setExpandedFolderGroups: React.Dispatch<React.SetStateAction<string[]>>;
  isInSidebar?: boolean;
  onSelect?: (id: string, event: React.MouseEvent) => void;
  isQuickAccessModal?: boolean;
  isHighlighted?: boolean;
  hasChildren?: boolean;
  forceShowChildren?: boolean;
  isSelected?: (
    columnIndex: number,
    rowIndex: number,
    subRowIndex: number
  ) => boolean;
  columnIndex?: number;
  rowIndex?: number;
  setSelectedItemIndex?: React.Dispatch<
    React.SetStateAction<{
      column: number;
      row: number;
      subRow: number;
    }>
  >;
}

export const useQuickAccessData = () => {
  const { data: folderStructure, isLoading: structureLoading } =
    useGetUserFolderStructure() as FolderStructure;

  function flattenFolderStructure(folder: FolderData): FolderData[] {
    return [folder, ...folder.subfolders.flatMap(flattenFolderStructure)];
  }

  function getAllFoldersWithQuickAccess(
    folderStructure: FolderTree | undefined
  ): FolderData[] {
    if (!folderStructure?.root?.folders) {
      return [];
    }

    // First, flatten the entire folder structure into a single array
    const allFolders = folderStructure?.root?.folders.flatMap(
      flattenFolderStructure
    );

    // Then, filter the flattened array for folders with quick access
    return allFolders.filter(folder => folder.isInQuickAccess);
  }

  const folders = getAllFoldersWithQuickAccess(folderStructure) || [];

  // Sorting the quick access maps by last interaction
  const maps = Array.from(collectQuickAccessMaps(folderStructure).values())
    .filter(map => map.isInQuickAccess)
    .sort(
      (a, b) =>
        new Date(a.lastInteraction).getTime() -
        new Date(b.lastInteraction).getTime()
    );

  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);

  const isFolder = (itemId: string): boolean => {
    // check whether it's a folder by checking if the property of folderId exists
    return folders.some(folder => folder.folderId === itemId);
  };

  const handleItemSelection = (itemId: string, event: React.MouseEvent) => {
    if (isFolder(itemId)) {
      dashboardStore.selectedMapIds = [];
      dashboardStore.selectedFolderIds = [itemId];
    } else {
      dashboardStore.selectedMapIds = [itemId];
      dashboardStore.selectedFolderIds = [];
    }
    setLastSelectedItem(itemId);
  };

  return { folders, maps, structureLoading, handleItemSelection };
};

export const folderHasChildren = (passedFolder: FolderData) => {
  const hasQuickAccessFolders =
    passedFolder.subfolders.filter(folder => folder.isInQuickAccess).length !==
    0;

  const hasQuickAccessMaps =
    passedFolder.maps.filter(map => map.isInQuickAccess).length !== 0;

  return hasQuickAccessFolders || hasQuickAccessMaps;
};

export const QuickAccessFolderItem: React.FC<QuickAccessFolderItemProps> = ({
  folder,
  level = 0,
  parentLevel,
  expandedFolderGroups,
  setExpandedFolderGroups,
  isInSidebar,
  onSelect,
  isQuickAccessModal,
  isHighlighted,
  forceShowChildren,
  isSelected,
  columnIndex,
  rowIndex,
  setSelectedItemIndex,
}) => {
  const navigate = useNavigate();

  const subfolders = (folder?.subfolders || []).filter(
    folder => folder.isInQuickAccess
  );

  const maps = folder.maps.filter(map => map.isInQuickAccess);

  return (
    <DropdownItemGroup
      level={`_${level}` as '_0'}
      forceShowChildren={forceShowChildren}
      folder={folder}
      parentColumn={parentLevel || 0}
      isInSidebar={isInSidebar || false}
      isFromQuickAccess={true}
      highlight={isHighlighted}
      hasChildren={folderHasChildren(folder)}
      additionalExpandingFunction={() =>
        setSelectedItemIndex
          ? setSelectedItemIndex({
              column: columnIndex,
              row: rowIndex,
              subRow: -1,
            })
          : undefined
      }
      body={
        <>
          {subfolders.map((subfolder, index) => (
            <QuickAccessFolderItem
              key={subfolder.folderId}
              folder={subfolder}
              level={level + 1}
              parentLevel={level}
              expandedFolderGroups={expandedFolderGroups}
              setExpandedFolderGroups={setExpandedFolderGroups}
              isInSidebar={isInSidebar || false}
              isQuickAccessModal={isQuickAccessModal}
              hasChildren={folderHasChildren(subfolder)}
              isSelected={isSelected}
              columnIndex={columnIndex}
              rowIndex={rowIndex}
              setSelectedItemIndex={setSelectedItemIndex}
              isHighlighted={
                isSelected(columnIndex, rowIndex, index) as boolean
              }
              // additionalExpandingFunction={()=> setSelectedItemIndex({column:columnIndex, row:rowIndex, subRow:index})}
            />
          ))}

          {maps.map((map, idx) => (
            <QuickAccessMapItem
              key={map.mapId}
              map={map}
              isInSidebar={isInSidebar}
              isQuickAccessModal={isQuickAccessModal}
              onSelect={onSelect}
              level={level + 1}
              handleClick={() => {
                navigate(`/map/${map.mapId}`);
                dashboardStore.quickAccessModal = false;
              }}
              isHighlighted={
                isSelected
                  ? (isSelected(
                      columnIndex,
                      rowIndex,
                      subfolders.length + idx
                    ) as boolean)
                  : false
              }
            />
          ))}
        </>
      }
      expandedFolderGroups={expandedFolderGroups}
      setExpandedFolderGroups={setExpandedFolderGroups}
      onSelect={e => onSelect(folder.folderId, e)}
      isQuickAccessModal={isQuickAccessModal}
    />
  );
};

export const QuickAccessMapItem: React.FC<QuickAccessMapItemProps> = ({
  map,
  level,
  onSelect,
  handleClick,
  isInSidebar,
  isQuickAccessModal,
  isHighlighted,
}) => {
  const icon = map?.icon?.name ? (
    <FolderMapIconRenderer
      icon={{
        name: map?.icon?.name || '',
        type: map?.icon?.type || '',
        color: map?.icon?.color || '',
      }}
    />
  ) : (
    (`${map?.icon}` as string)
  );

  return (
    <DropdownItem
      level={`_${level}` as '_0'}
      highlight={isHighlighted}
      isLeftIconHidden
      leftIcon2={map?.icon ? icon : undefined}
      style={{
        width: '100%',
        maxWidth: !isQuickAccessModal ? '200px' : '220px',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      }}
      size={isInSidebar ? 'small' : 'medium'}
      name={
        <div
          style={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
          }}
          title={map?.name}
        >{`${map?.name}`}</div>
      }
      onSelect={e => onSelect?.(map.mapId, e)}
      handleClick={handleClick || undefined}
      itemPassed={map}
    />
  );
};
