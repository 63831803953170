/**
 * This encapsulate access to the to the selected array object
 * Allows for a single location to make changes, updates and improvements
 *
 *
 */

import Selecto from 'react-selecto';
import { generalStore } from 'utils/stores/generalStore';
import {
  addItemToSelected,
  currentMapStateStore,
  getSelectedNoteIDAndStackAtPosition,
  removeItemFromSelected,
} from 'utils/stores/mapStore';
import selectedContentsStore, {
  SelectedContent,
} from 'utils/stores/Selection/selectedContentStore';
import handleSelectContent from '../handleClickItem';
import { gestureStore } from 'utils/stores/gestureStore';
import { getStack } from 'utils/mapStoreFN/mapStoreFN_stacks';

/**
 * This returns an array of selected items for the current map
 * @param ignoreID(optional) - returns all selectedID except the passed ID
 */

const getSelectedIDs = (ignoreID?: string): string[] => {
  const useSelectionV2 = generalStore?.flags?.mapmap_selectionv2;

  const mapID = generalStore.mapID;

  if (useSelectionV2) {
    const selectedContent = selectedContentsStore.getAllSelected(
      null,
      null,
      ignoreID
    );

    return selectedContent.querriedContentIDs;
  }
  const selected = currentMapStateStore[mapID]?.selected;
  return selected;
};

export default getSelectedIDs;

export function selectNextNoteInStack(mapID: string) {
  const { itemID, stackID } = getSelectedNoteIDAndStackAtPosition(
    mapID,
    null,
    'LAST'
  );
  if (!itemID && stackID) return;
  const stackData = getStack(stackID, mapID);

  if (!stackData || stackData.items.length < 2) return;

  const noteIndex = stackData.items.findIndex(note => note.itemId === itemID);
  if (noteIndex !== -1 && stackData.items[noteIndex - 1]) {
    addItemToSelected(
      {
        contentID: stackData.items[noteIndex - 1].itemId,
        type: 'Note',
        parentID: stackData.stackId,
        parentType: 'STACK',
      },
      mapID
    );

    return;
  }
}

export function unselectCurrentNoteInStack(mapID: string) {
  const { itemID, stackID } = getSelectedNoteIDAndStackAtPosition(
    mapID,
    null,
    'LAST'
  );

  if (itemID && stackID) {
    removeItemFromSelected(
      {
        contentID: itemID,
        type: 'Note',
        parentID: stackID,
        parentType: 'STACK',
      },
      mapID
    );
  }
}

export const onSelect = (
  e: React.MouseEvent,
  isSelected: boolean,
  selectedContent: SelectedContent,
  selectoRef: React.RefObject<Selecto | null>
) => {
  if (gestureStore.Dragging.isDragging) {
    return;
  }
  const mapId = generalStore.mapID;
  handleSelectContent(
    e,
    selectedContent,
    isSelected,
    mapId,
    selectoRef || null
  );
};
