import { useFlag } from '@unleash/proxy-client-react';
import Stack from 'CoreComponents/stacks/Stack';
import { useParams } from 'react-router-dom';
import { insertMode } from 'utils/stores/mapMode/modesStore';
import { currentMapContentStore } from 'utils/stores/mapStore';
import { useSnapshot } from 'valtio';

function RenderStacks({ selectoRef }) {
  const mapId = useParams().mapId!;

  const useSelectionV2 = useFlag('mapmap_selectionv2');

  const currentStackState = useSnapshot(currentMapContentStore[mapId]).value
    .stacks;

  const insertModeSnap = useSnapshot(insertMode);

  return currentStackState?.map((stack: any, index) => (
    <Stack
      key={stack.stackId}
      stack={stack}
      insertMode={insertModeSnap}
      selectoRef={selectoRef}
      stackIndex={index}
      useSelectionV2={useSelectionV2}
      mapID={mapId}
    />
  ));
}

/*
stacks are already wrapped in memo 
*/

export default RenderStacks;
