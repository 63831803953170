import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { logout } from 'propro-common-components';

export class AppError extends Error {
  public readonly context: string;
  public readonly originalError: Error | null;
  public readonly statusCode: number | null;
  public readonly errorType: ErrorType;
  public readonly timestamp: Date;
  public readonly metadata: Record<string, any>;

  constructor(
    message: string,
    context: string,
    errorType: ErrorType,
    originalError?: Error | AxiosError,
    metadata: Record<string, any> = {}
  ) {
    super(message);
    this.name = this.constructor.name;
    this.context = context;
    this.errorType = errorType;
    this.originalError = originalError || null;
    this.statusCode =
      originalError && 'response' in originalError
        ? originalError.response?.status || null
        : null;
    this.timestamp = new Date();
    this.metadata = metadata;

    Object.setPrototypeOf(this, new.target.prototype);
  }

  public toJSON() {
    return {
      name: this.name,
      message: this.message,
      context: this.context,
      errorType: this.errorType,
      statusCode: this.statusCode,
      timestamp: this.timestamp,
      metadata: this.metadata,
      stack: this.stack,
    };
  }
}

export enum ErrorType {
  NETWORK = 'NETWORK_ERROR',
  API = 'API_ERROR',
  VALIDATION = 'VALIDATION_ERROR',
  AUTHENTICATION = 'AUTHENTICATION_ERROR',
  AUTHORIZATION = 'AUTHORIZATION_ERROR',
  NOT_FOUND = 'NOT_FOUND_ERROR',
  RATE_LIMIT = 'RATE_LIMIT_ERROR',
  TIMEOUT = 'TIMEOUT_ERROR',
  DATABASE = 'DATABASE_ERROR',
  EXTERNAL_SERVICE = 'EXTERNAL_SERVICE_ERROR',
  CONFIGURATION = 'CONFIGURATION_ERROR',
  UNEXPECTED = 'UNEXPECTED_ERROR',
}

export const handleError = (
  error: unknown,
  context: string,
  metadata: Record<string, any> = {}
): AppError => {
  console.error(`Error in ${context}:`, error);

  if (error instanceof AppError) {
    return error;
  }

  let appError: AppError;

  if (error instanceof AxiosError) {
    const statusCode = error.response?.status;
    const errorMap = {
      400: {
        message: 'Invalid request',
        errorType: ErrorType.VALIDATION,
      },
      401: {
        message: 'Authentication failed',
        errorType: ErrorType.AUTHENTICATION,
      },
      403: {
        message: 'You do not have permission to perform this action',
        errorType: ErrorType.AUTHORIZATION,
      },
      404: {
        message: 'Requested resource not found',
        errorType: ErrorType.NOT_FOUND,
      },
      422: {
        message: 'Validation failed',
        errorType: ErrorType.VALIDATION,
      },
      429: {
        message: 'Too many requests',
        errorType: ErrorType.RATE_LIMIT,
      },
      500: {
        message: 'Internal server error',
        errorType: ErrorType.API,
      },
    };

    const { message, errorType } = errorMap[
      statusCode as keyof typeof errorMap
    ] || {
      message: 'An unexpected error occurred',
      errorType: ErrorType.UNEXPECTED,
    };

    appError = new AppError(message, context, errorType, error, metadata);
  } else if (error instanceof Error) {
    if (error.name === 'TimeoutError') {
      appError = new AppError(
        error.message,
        context,
        ErrorType.TIMEOUT,
        error,
        metadata
      );
    } else {
      appError = new AppError(
        error.message,
        context,
        ErrorType.UNEXPECTED,
        error,
        metadata
      );
    }
  } else {
    appError = new AppError(
      'An unexpected error occurred',
      context,
      ErrorType.UNEXPECTED,
      undefined,
      metadata
    );
  }

  logError(appError);
  return appError;
};

export const logError = (error: AppError): void => {
  console.error(`[${error.context}] ${error.name}: ${error.message}`);
  if (error.originalError) {
    console.error('Original error:', error.originalError);
  }
};

export const getUserFriendlyErrorMessage = (error: AppError): string => {
  const errorMessages = {
    [ErrorType.NETWORK]:
      'There seems to be a problem with your internet connection. Please check and try again.',
    [ErrorType.API]:
      "We're having trouble communicating with our servers. Please try again later.",
    [ErrorType.VALIDATION]:
      'The information you provided is invalid. Please check and try again.',
    [ErrorType.AUTHENTICATION]:
      'Your session has expired. Please log in again to continue.',
    [ErrorType.AUTHORIZATION]:
      "You don't have permission to perform this action. If you think this is a mistake, please contact support.",
    [ErrorType.NOT_FOUND]:
      "We couldn't find what you're looking for. It may have been moved or deleted.",
    [ErrorType.RATE_LIMIT]:
      "You've made too many requests. Please wait a moment and try again.",
    [ErrorType.TIMEOUT]:
      'The request took too long to complete. Please try again.',
    [ErrorType.DATABASE]:
      "We're having trouble accessing our database. Please try again later.",
    [ErrorType.EXTERNAL_SERVICE]:
      "One of our external services is not responding. We're working on resolving this issue.",
    [ErrorType.CONFIGURATION]:
      "There's a problem with our system configuration. Our team has been notified and is working on it.",
  };

  return (
    errorMessages[error.errorType as keyof typeof errorMessages] ||
    "An unexpected error occurred. We're working on fixing it. Please try again later."
  );
};

export function axiosErrorHandlingInterceptor() {
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as AxiosRequestConfig & {
        _retry?: boolean;
      };

      if (
        error.response?.status === 302 &&
        typeof error.response.data === 'object' &&
        error.response.data &&
        'url' in error.response.data
      ) {
        return error.response;
      }

      if (error.response?.status === 401 && !originalRequest?._retry) {
        logout();
        return Promise.reject(error);
      }

      const appError = handleError(error, 'Response Interceptor', {
        url: error.config?.url,
        method: error.config?.method,
      });
      return Promise.reject(appError);
    }
  );
}
