import { Matrix } from 'CoreComponents/Canvas/canvas';
import { Action } from 'CoreComponents/Item/Item';
import { EditingItemState } from 'CoreComponents/Note/Note';
import { Resizable } from 're-resizable';
import { ReactNode, useState } from 'react';
import { getScaleFromMatrix } from 'utils/helpers/Canvas/clamp-zoom';
import { resizeAllSelected } from 'utils/resizable/resizableUtils';
import { cNoteConfig } from 'utils/stores/constants';
import { setIsResizing } from 'utils/stores/gestureStore';
import {
  addItemToSelected,
  currentMapStateStore,
  updateItemOrAreaStyle,
} from 'utils/stores/mapStore';
import selectedContentsStore from 'utils/stores/Selection/selectedContentStore';
import { ItemData, Style } from 'utils/stores/types';

type ResizeProps = {
  children: ReactNode;
  TargetComponent: ItemData;
  editingState: EditingItemState;
  parentID: string;
  isResizing?: boolean;
  mapID: string;
  type: 'Note' | 'Image';
  updateNoteState?: React.Dispatch<Action>;
  itemRef?: React.MutableRefObject<HTMLDivElement>;
};

const applyWidth = props => {
  if (props.TargetComponent.style?.wrap) {
    // make note stretch to defaultNote width if currentText content is less

    if (props.editingState === 'no') {
      return 'auto';
    }
    if (props.editingState === 'yes') {
      return 'auto';
    }
  }

  // flow when wrap is false
  if (props.editingState === 'no') {
    const customWidth = props.TargetComponent?.style?.customWidth;

    const setWidth = customWidth ? customWidth : 'auto';
    return setWidth;
  }

  if (props.editingState === 'yes') {
    const currentWidth = props.TargetComponent?.style?.customWidth;
    const isWrapped = getNumberOfLines(props.itemRef);
    if (
      (currentWidth && isWrapped > 1) ||
      currentWidth > cNoteConfig.MINWIDTH
    ) {
      return currentWidth;
    }
    return cNoteConfig.MINWIDTH;
  }
};

export function getNumberOfLines(
  parentElement: React.MutableRefObject<HTMLDivElement | HTMLElement>
): number | undefined {
  if (!parentElement.current) return;
  const textNode = parentElement.current.querySelector('p');
  if (!textNode) return;

  const style = window.getComputedStyle(textNode);
  const fontSize = parseFloat(style.fontSize);
  let lineHeight = parseFloat(style.lineHeight);

  // If lineHeight is not a number, it might be 'normal' or not set, so we estimate it
  if (isNaN(lineHeight)) {
    lineHeight = fontSize * 1.2; // A common approximation is 1.2 times the font size
  }

  const clientHeight = textNode.clientHeight;
  const numberOfLines = Math.round(clientHeight / lineHeight);
  return numberOfLines;
}
const getDragScale = (
  setDragScale: React.Dispatch<React.SetStateAction<number>>,
  mapID: string
) => {
  const canvaState = currentMapStateStore[mapID];
  const currentZoomLevel = getScaleFromMatrix(
    new Matrix(canvaState.canvas.CanvasTransform)
  );

  setDragScale(currentZoomLevel);
  return;
};

export function Resize(props: ResizeProps) {
  const [dragScale, setDragScale] = useState(1);

  return (
    <Resizable
      scale={dragScale}
      className={`resizable `}
      enable={{
        top: false,
        right: true,
        bottom: props.type === 'Image',
        left: true,
        topRight: props.type === 'Image',
        bottomRight: props.type === 'Image',
        bottomLeft: false,
        topLeft: false,
      }}
      onResizeStart={e => {
        e.preventDefault();
        e.stopPropagation();
        setIsResizing(true);

        getDragScale(setDragScale, props.mapID);
        props?.updateNoteState({
          target: 'resizing',
          payload: true,
        });
        addItemToSelected(
          {
            contentID: props.TargetComponent.itemId,
            type: 'Note',
            parentType: 'STACK',
            parentID: props.parentID,
          },
          props.mapID
        );
      }}
      size={{
        width: applyWidth(props),
        height:
          props.type === 'Image'
            ? props?.TargetComponent?.style?.height ||
              props?.TargetComponent?.style?.customWidth
            : 'auto',
      }}
      onResize={function (event, direction, elementRef, delta) {
        resizeAllSelected(
          false,
          props.TargetComponent.itemId,

          props.mapID,
          dragScale,
          props.type,

          event,
          direction,
          elementRef,
          delta
        );
      }}
      lockAspectRatio={props.type === 'Image' ? true : false}
      onResizeStop={function (event, direction, elementRef, delta) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
        props?.updateNoteState({
          target: 'resizing',
          payload: false,
        });

        const getWidth = elementRef.clientWidth;
        const getHeight = elementRef.clientHeight;
        let newDimention: Style =
          props.type === 'Image'
            ? { customWidth: getWidth, wrap: false, height: getHeight }
            : { customWidth: getWidth, wrap: false };

        updateItemOrAreaStyle(
          props.TargetComponent.itemId,
          newDimention,
          props.mapID,
          props.parentID
        );

        resizeAllSelected(
          true,
          props.TargetComponent.itemId,
          props.mapID,
          dragScale,
          props.type,

          event,
          direction,
          elementRef,
          delta
        );

        return;
      }}
      maxWidth={cNoteConfig.MAXWIDTH}
      maxHeight={cNoteConfig.MAXHEIGHT}
      minWidth={`${(props?.TargetComponent?.style?.fontSize || 12) + 20}`}
      minHeight={`${(props?.TargetComponent?.style?.fontSize || 12) + 20}`}
      data-resizable={`${props.TargetComponent.itemId}`}
      data-elementType={`${props.type}`}
      data-minWidth={`${(props?.TargetComponent?.style?.fontSize || 12) + 20}`}
    >
      <div
        style={{
          width: 'inherit',
          height: '100%',
          maxWidth: `${cNoteConfig.MAXWIDTH}px`,
        }}
      >
        {props.children}
      </div>
    </Resizable>
  );
}
