import { HTMLElementRefOf } from '@plasmicapp/react-web';
import * as React from 'react';
import {
  DefaultFancyButtonProps,
  PlasmicFancyButton,
} from './plasmic/map_map/PlasmicFancyButton';

export interface FancyButtonProps extends DefaultFancyButtonProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
}

function FancyButton_(
  { onClick, style, ...props }: FancyButtonProps,
  ref: HTMLElementRefOf<'div'>
) {
  return (
    <PlasmicFancyButton
      onClick={onClick}
      fancyButtonInnerContainer={{
        style: {
          ...style,
          background: 'url(/images/fancy.png)',
          backgroundSize: '100%',
        },
      }}
      {...props}
    />
  );
}

const FancyButton = React.forwardRef(FancyButton_);
export default FancyButton;
