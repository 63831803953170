import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { getConnectionPoints } from 'CoreComponents/Connections/connectionUtils';
import {
  getNormalizedCoordinates,
  getScaleFromMatrix,
} from 'utils/helpers/Canvas/clamp-zoom';
import { ConnectingItems } from 'utils/stores/connectingItemStore';
import {
  currentMapContentStore,
  currentMapStateStore,
  getIndex,
} from 'utils/stores/mapStore';
import {
  ConnectionData,
  ConnectionID,
  ItemData,
  NoteItemData,
} from 'utils/stores/types';
import { getParentIdFromItemID, getStackAndItem } from './mapStoreFN_stacks';
import { createItemId, getItemData } from './mapStoreFN_items';
import { Note } from 'CoreComponents/Item/noteUtils';

export function DrawConnection(
  itemID: string,
  mapID: string
  // position: { x: number; y: number }
) {
  if (!mapID) return;
  const stackAndItem = getStackAndItem(itemID, mapID);
  if (!stackAndItem) return;

  const connectionID = stackAndItem.itemData.connections;
  if (currentMapContentStore[mapID].value.connections.length >= 1) {
    connectionID.forEach(id => {
      const connectionIndex = currentMapContentStore[
        mapID
      ].value.connections.findIndex(({ connectionId }) => connectionId === id);

      const connectionData =
        currentMapContentStore[mapID].value.connections[connectionIndex];

      if (connectionIndex === undefined || !connectionData) return;

      const canvasTransform =
        currentMapStateStore[mapID].canvas?.CanvasTransform;
      const zoomLevel = getScaleFromMatrix(new Matrix(canvasTransform));
      const shortestPath = getConnectionPoints(
        connectionData.from.id,
        connectionData.to.id,
        zoomLevel
      );

      if (shortestPath) {
        const normalisedCordsFrom = getNormalizedCoordinates(
          canvasTransform,
          shortestPath.from.x,
          shortestPath.from.y
        );
        const normalisedCordsTo = getNormalizedCoordinates(
          canvasTransform,
          shortestPath.to.x,
          shortestPath.to.y
        );

        connectionData.from = {
          ...connectionData.from,
          position: {
            x: normalisedCordsFrom.x,
            y: normalisedCordsFrom.y,
          },
        };
        connectionData.to = {
          ...connectionData.to,
          position: {
            x: normalisedCordsTo.x,
            y: normalisedCordsTo.y,
          },
        };
      }
    });
  }
}

export function addConnectionLabel(connectionID: string, mapID: string) {
  const newLabel = new Note(
    true,
    null,
    'Note',
    null,
    {
      wrap: true,
    },
    'CONNECTION',
    'label'
  ) as NoteItemData;

  const connection = retrieveConnectionData(connectionID, mapID);

  if (connection) {
    connection.label = newLabel;
  }
}

export function delectConnectionLabel(labelID: string, mapID: string) {
  const connectionID = getParentIdFromItemID(labelID);
  const connection = retrieveConnectionData(connectionID, mapID);
  if (connection) {
    connection.label = null;
  }
}
export function updateLineConnectionCord(
  mapID: string,
  connectionID: ConnectionID[],
  itemID: string
  // position: { x: number; y: number }
) {
  if (!currentMapContentStore[mapID].value.connections) return;

  for (let i = 0; i < connectionID.length; i++) {
    const connectionIndex = getIndex(connectionID[i], mapID);

    const connectionData =
      currentMapContentStore[mapID].value.connections[connectionIndex];
    if (!connectionData) return;

    const canvasTransform = currentMapStateStore[mapID].canvas?.CanvasTransform;
    const zoomLevel = getScaleFromMatrix(new Matrix(canvasTransform));

    const shortestPath = getConnectionPoints(
      connectionData.from.id,
      connectionData.to.id,
      zoomLevel
    );

    if (shortestPath) {
      const normalisedCordsFrom = getNormalizedCoordinates(
        canvasTransform,
        shortestPath.from.x,
        shortestPath.from.y
      );
      const normalisedCordsTo = getNormalizedCoordinates(
        canvasTransform,
        shortestPath.to.x,
        shortestPath.to.y
      );

      connectionData.from = {
        ...connectionData.from,
        position: {
          x: normalisedCordsFrom.x,
          y: normalisedCordsFrom.y,
        },
      };
      connectionData.to = {
        ...connectionData.to,
        position: {
          x: normalisedCordsTo.x,
          y: normalisedCordsTo.y,
        },
      };
    }
  }
}

export function generateConnectionID(from: string, to: string): string {
  // Simple function to simulate hashing

  function simpleHash(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }

    return hash;
  }

  const hashA = simpleHash(from);
  const hashB = simpleHash(to);
  const hash = Math.abs(hashA - hashB);

  return String('connection_' + hash);
}

export function createConnection(
  connectItems: [ConnectingItems, ConnectingItems],
  mapID: string,
  type: 'line' | 'arrow',
  connectionColor?: string
) {
  const connectionID = generateConnectionID(
    connectItems[0].itemId,
    connectItems[1].itemId
  );

  const connection = currentMapContentStore[mapID].value.connections.find(
    ({ connectionId }) => connectionId === connectionID
  );

  if (connection) {
    return handleConnectedItem(
      type,
      connection,
      {
        itemID: connectItems[0].itemId,
        position: connectItems[0].position,
      },
      mapID
    );
  }

  const newConnection: ConnectionData = {
    type,
    connectionId: connectionID,
    name: 'CONNECTION',
    position: { x: 0, y: 0 },

    from: {
      id: connectItems[0].itemId,
      position: connectItems[0].position,
    },
    to: {
      id: connectItems[1].itemId,
      position: connectItems[1].position,
    },
    connectionColor: connectionColor,
    label: null,
    lastInteraction: new Date(),
  };
  currentMapContentStore[mapID].value.connections.push(newConnection);

  updateItemConnection(
    connectItems[0].itemId,
    newConnection.connectionId,
    mapID
  );
  updateItemConnection(
    connectItems[1].itemId,
    newConnection.connectionId,
    mapID
  );
  return;
}

export function retrieveConnectionData(
  connectionID: string,
  mapID: string
): ConnectionData | undefined | null {
  return currentMapContentStore[mapID].value.connections.find(
    ({ connectionId }) => connectionId === connectionID
  );
}

const removeConnection = (connections: string[], mapID: string) => {
  currentMapContentStore[mapID].value.connections = currentMapContentStore[
    mapID
  ].value.connections.filter(
    ({ connectionId }) => !connections.includes(connectionId) // filter out any connection that is contained in the connections array
  );
};

export const removeConnectionIDFromItem = (
  itemID: string,
  mapID: string,
  connectionIDToRemove: string
) => {
  const itemNote = getItemData(itemID, mapID);

  if (itemNote) {
    itemNote.connections = [
      ...itemNote.connections.filter(id => id !== connectionIDToRemove),
    ];
  }
  return;
};

export const removeDeletedItemConnection = (
  itemID: string,
  connectionIDs: string[],
  mapID: string,
  currentConnectionIndex = 0
) => {
  // prevent stack overflowing  if called with an empty array
  if (
    connectionIDs.length === 0 ||
    connectionIDs.length === -1 ||
    connectionIDs.length === undefined
  )
    return;

  /**
   ** A Recursive function that remove all connection when a note with a connection or connection is deleted
   * this makes a recursive call, looping through the connectionIDs
   * removing the connectionIDs from every note.connection array that the deleted
   * note has a connection to and finally calls removeConnection when all notes
   * that the deleted item was connected to has been removed
   */

  if (connectionIDs.length === currentConnectionIndex) {
    return removeConnection(connectionIDs, mapID);
  }

  const getConnectionObject = retrieveConnectionData(
    connectionIDs[currentConnectionIndex],
    mapID
  );
  if (getConnectionObject) {
    const { from, to } = getConnectionObject;

    // Ensure we are not passing the deleted Item ID
    const targetItemID = from.id === itemID ? to.id : from.id;
    removeConnectionIDFromItem(
      targetItemID,
      mapID,
      connectionIDs[currentConnectionIndex]
    );
    removeDeletedItemConnection(
      itemID,
      connectionIDs,
      mapID,
      (currentConnectionIndex += 1)
    );
  } else {
    removeDeletedItemConnection(
      itemID,
      connectionIDs,
      mapID,
      (currentConnectionIndex += 1)
    );
  }
};

export const updateItemConnection = (
  itemID: string,
  newConnection: ConnectionID,
  mapID: string
) => {
  const itemData = getItemData(itemID, mapID);
  if (itemData) {
    itemData.connections.push(newConnection);
  }
  return;
};
export function handleConnectedItem(
  incomingConnectionType: 'line' | 'arrow',
  currentConnection: ConnectionData,
  connectingItem: {
    itemID: string;
    position: { x: number; y: number };
  },
  mapID: string
): void {
  // Create double connection
  if (
    !currentConnection.isDouble &&
    currentConnection.from.id !== connectingItem.itemID &&
    incomingConnectionType === 'arrow' &&
    currentConnection.type === 'arrow'
  ) {
    currentConnection.isDouble = true;
    return;
  }

  // if current connection is line remove it
  if (currentConnection.type === incomingConnectionType) {
    removeConnectionIDFromItem(
      currentConnection.from.id,
      mapID,
      currentConnection.connectionId
    );
    removeConnectionIDFromItem(
      currentConnection.to.id,
      mapID,
      currentConnection.connectionId
    );
    removeConnection([currentConnection.connectionId], mapID);

    return;
  }

  // if type is arrow update connection to arrow and the connection direction
  if (currentConnection.type === 'line' && incomingConnectionType === 'arrow') {
    currentConnection.type = 'arrow';

    if (currentConnection.from.id === connectingItem.itemID) return;
    const to = currentConnection.from;
    currentConnection.from = {
      id: connectingItem.itemID,
      position: connectingItem.position,
    };
    currentConnection.to = to;
    return;
  }
  // change to line if connection is arrow
  if (incomingConnectionType === 'line' && currentConnection.type === 'arrow') {
    currentConnection.type = 'line';
    currentConnection.isDouble = false;
  }

  if (
    currentConnection.type === 'arrow' &&
    incomingConnectionType === 'arrow'
  ) {
    removeConnectionIDFromItem(
      currentConnection.from.id,
      mapID,
      currentConnection.connectionId
    );
    removeConnectionIDFromItem(
      currentConnection.to.id,
      mapID,
      currentConnection.connectionId
    );
    removeConnection([currentConnection.connectionId], mapID);
    removeConnection([currentConnection.connectionId], mapID);
    return;
  }

  return;
}
