import { useParams } from 'react-router-dom';
import useItemStyleOperations from 'utils/hooks/useItemStyleOperations';
import { HotkeysProps } from 'utils/stores/hotKeysStore';
import { getMapID } from 'views/core/mapView';

export default function useItemKeys() {
  const { mapId } = useParams();

  const {
    applyFade,
    triggerBorderThicknessSelection,
    triggerBorderColorSelection,
    triggerFillColorSelection,
    triggerTextColorSelection,
    backwardIndentation,
    forwardIndentation,
    applyTextAlignment,
    applyFontSize,
    applyBold,
    applyItalic,
    applyUnderline,
    applyGlowyBorder,
    changeItemToTask,
    makeItemHeadline,
  } = useItemStyleOperations(mapId);

  // isC
  const defaultKeys: HotkeysProps = {
    'Apply Fade': {
      shortcut: 'mod+d', // ! change to mod+shift+d
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: e => {
        e.stopPropagation();
        e.preventDefault();

        applyFade();
      },
      shouldPreventDefault: true,
      isCustomizable: true,
    },
    'Border Color Selection': {
      shortcut: 'mod+alt+b',
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: triggerBorderColorSelection,
      isCustomizable: true,
      shouldPreventDefault: true,
    },

    'Text Color Selection': {
      shortcut: 'mod+alt+t',
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: triggerTextColorSelection,
      isCustomizable: true,
    },

    'Fill Color Selection': {
      shortcut: 'mod+alt+f',
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: e => {
        e.preventDefault();
        triggerFillColorSelection();
      },
      isCustomizable: true,
    },

    'Border Thickness Selection': {
      shortcut: 'mod+alt+o',
      triggerOnEditing: true,
      alternativeShortcut: null,
      action: triggerBorderThicknessSelection,
      isCustomizable: true,
      shouldPreventDefault: true,
    },

    'Text Alignment Right': {
      shortcut: 'alt+arrowRight',
      triggerOnEditing: false,
      alternativeShortcut: null,
      action: () => applyTextAlignment('ArrowRight'),
      isCustomizable: true,
      shouldPreventDefault: true,
    },

    'Text Alignment Left': {
      shortcut: 'alt+ArrowLeft',
      triggerOnEditing: false,
      alternativeShortcut: null,
      action: () => applyTextAlignment('ArrowLeft'),
      isCustomizable: true,
      shouldPreventDefault: true,
    },

    'Text Bold': {
      shortcut: 'mod+b',
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: applyBold,
      isCustomizable: true,
      shouldPreventDefault: true,
    },

    'Text Underline': {
      shortcut: 'mod+u',
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: applyUnderline,
      isCustomizable: true,
      shouldPreventDefault: true,
    },

    'Text Italic': {
      shortcut: 'mod+i',
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: applyItalic,
      isCustomizable: true,
      shouldPreventDefault: true,
    },

    'Glowy Border': {
      shortcut: 'mod+g',
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: applyGlowyBorder,
      isCustomizable: true,
      shouldPreventDefault: true,
    },

    'Forward Indentation': {
      shortcut: 'tab',
      alternativeShortcut: 'mod+ArrowRight',
      triggerOnEditing: true,
      action: forwardIndentation,
      shouldPreventDefault: true,
    },

    'Backward Indentation': {
      shortcut: 'shift+tab',
      alternativeShortcut: 'mod+ArrowLeft',
      triggerOnEditing: true,
      action: backwardIndentation,
      shouldPreventDefault: true,
    },
    'Font Size Increase': {
      shortcut: 'mod+alt+period',
      // [
      //   'mod+alt+period',
      //   'mod+alt+equal',
      //   'mod+alt+numpadAdd', // visit this
      //   'mod+alt+slash', // visit this
      // ],
      alternativeShortcut: 'mod+alt+equal',
      triggerOnEditing: true,
      action: () => applyFontSize('increment'),
      shouldPreventDefault: true,
    },
    'Font Size Decrease': {
      shortcut: 'mod+alt+minus',
      alternativeShortcut: 'mod+alt+comma',
      triggerOnEditing: true,
      action: () => applyFontSize('decrement'),
      shouldPreventDefault: true,
    },

    Task: {
      shortcut: 'mod+alt+a',
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: () => changeItemToTask(),
      shouldPreventDefault: true,
    },
    Headline: {
      shortcut: 'mod+alt+h',
      alternativeShortcut: null,
      triggerOnEditing: true,
      action: () => makeItemHeadline(),
      shouldPreventDefault: true,
    },
  };

  return {
    defaultKeys,
  };
}
