import { gestureStore, setIsEditing } from 'utils/stores/gestureStore';
import { anchorMode, creatingAreaMode } from 'utils/stores/mapMode/modesStore';
import {
  closeColorPicker,
  currentMapStateStore,
  deleteContent,
  resetSelected,
} from 'utils/stores/mapStore';
import { ItemData } from 'utils/stores/types';

type CallSites =
  | 'color selector'
  | 'Note'
  | 'canvas'
  | 'slate Editor'
  | 'thickness selector'
  | 'creating area';

const handleClose = (
  setOpenColorPicker: React.Dispatch<React.SetStateAction<number | null>>,
  openColorPicker: null | number,
  mapID: string
) => {
  if (openColorPicker !== null) {
    return setOpenColorPicker(null);
  } else {
    return closeColorPicker(mapID);
  }
};

const handleCloseThicknessSelector = (mapID: string) => {
  anchorMode.reset();
};

const handleSlateOnBlur = (mapID: string, item: ItemData) => {
  setIsEditing(false);
  creatingAreaMode.reset();
  currentMapStateStore[mapID].selected = [item.itemId];
};

const clearSelected = (mapID: string) => {
  return resetSelected(mapID);
};

function useESC() {
  function reducer(callSite: CallSites): Function {
    switch (callSite) {
      case 'canvas':
        return clearSelected;

      case 'color selector':
        return handleClose;
      case 'slate Editor':
        return handleSlateOnBlur;
      case 'thickness selector':
        return handleCloseThicknessSelector;

      default:
        return () => {};
    }
  }
  const dispatch = (callSite: CallSites) => {
    return reducer(callSite);
  };
  return { dispatch };
}
export default useESC;
