import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { Note } from 'CoreComponents/Item/noteUtils';
import { getScaleFromMatrix } from 'utils/helpers/Canvas/clamp-zoom';
import { creatingAreaMode } from 'utils/stores/mapMode/modesStore';
import { currentMapStateStore } from 'utils/stores/mapStore';
import { themeStore } from 'utils/stores/themeStore';
import { AreaData, Name, NoteItemData, Style } from 'utils/stores/types';
import { v4 as uuidv4 } from 'uuid';
import { mousePosition } from './CreateArea';
import { createNewArea } from 'utils/mapStoreFN/mapStoreFN_areas';
import { transformedMouseCordinates } from 'CoreComponents/Canvas/canvasUtils';
import { generalStore } from 'utils/stores/generalStore';

export type Size = AreaData['size'];
export type Position = AreaData['position'];

function creatTitleNote(position: Position, mapID: string): NoteItemData {
  const isStackHeadline = creatingAreaMode.areaAndStack;
  return new Note(
    true,
    null,
    'Note',
    null,

    isStackHeadline
      ? {
          ...themeStore.styleObjects[0],
          fillColor: themeStore.canvasBackgroundColor,
        }
      : {},
    'AREA',
    'titleNote'
  ) as NoteItemData;
}

function applyAreaSize(mapID: string, width?: number, height?: number): Size {
  const curentMapState = currentMapStateStore[mapID];
  const isStackAndArea = creatingAreaMode.areaAndStack;

  const zoomLevel = getScaleFromMatrix(
    new Matrix(curentMapState.canvas?.CanvasTransform)
  );
  if (isStackAndArea) {
    return {
      width: 100 / zoomLevel,
      height: 100 / zoomLevel,
    };
  }

  if (width && height) {
    return {
      width: width / zoomLevel,
      height: height / zoomLevel,
    };
  }

  return {
    width: 400,
    height: 400,
  };
}

/**
 * Represents an area within a map.
 *
 * @implements {AreaData}
 */
export class Area implements AreaData {
  /**
   * Unique identifier for the area.
   * @type {string}
   */
  areaId: string;

  /**
   * Size of the area.
   * @type {Size}
   */
  size: Size;

  /**
   * Position of the area on the map.
   * @type {Position}
   */
  position: Position;

  /**
   * Note item data associated with the title of the area.
   * @type {NoteItemData}
   */
  titleNote: NoteItemData;

  /**
   * List of stack identifiers that the area encompasses.
   * @type {string[]}
   */
  encompassingStacks: string[];

  /**
   * Timestamp of the last interaction with the area.
   * @type {Date}
   */
  lastInteraction: Date;

  /**
   * Timestamp of the last synchronization of the area.
   * @type {Date}
   */
  lastSync: Date;

  /**
   * Indicates whether the area is both an area and a stack.
   * @type {boolean}
   */
  isAreaAndStack: boolean;

  /**
   * Style properties for the area.
   * @type {Style}
   */
  style: Style;

  /**
   * Indicates whether the area is marked for deletion.
   * @type {boolean}
   */
  delete: boolean;

  /**
   * List of stamp identifiers associated with the area.
   * @type {string[]}
   */
  stamps: string[];

  /**
   * Name of the area.
   * @type {Name}
   */
  name: Name;

  /**
   * Creates an instance of Area.
   *
   * @param {string} mapID - The identifier of the map.
   * @param {boolean} isAreaAndTitle - Indicates if the area has a title note.
   * @param {Position} position - The position of the area on the map.
   * @param {{ width: number; height: number }} [size] - The size of the area.
   */
  constructor(
    position: Position,
    size?: { width: number; height: number },
    style: Style = {},
    mapID: string = generalStore.mapID,
    isAreaAndTitle = false
  ) {
    this.areaId = 'area_' + uuidv4();
    this.titleNote = isAreaAndTitle ? creatTitleNote(position, mapID) : null;
    this.position = position;
    this.lastInteraction = new Date();
    this.lastSync = new Date();
    this.style = style;
    this.stamps = [];
    this.encompassingStacks = [];
    this.size = applyAreaSize(mapID, size?.width, size?.height);
    this.delete = false;
    this.name = 'AREA';
  }
}

export const createArea = (
  areaAndTitle: boolean,
  mapId: string,
  position = mousePosition,
  width?: number,

  height?: number
) => {
  const newPosition = transformedMouseCordinates(position, mapId);

  const area = new Area(
    newPosition,
    { width, height },
    {},
    mapId,
    areaAndTitle
  );

  area.style = creatingAreaMode.areaAndStack
    ? {}
    : {
        border: {
          thickness: '_3',
          color: '#f0ead6',
          isSquared: true,
        },
      };
  createNewArea(mapId, area);
};
