import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Slate,
  Editable,
  withReact,
  RenderLeafProps,
  RenderElementProps,
} from 'slate-react';
import { Editor, createEditor, Transforms, Descendant } from 'slate';
import {
  handleChangeTextAndNoteWidth,
  deleteContent,
} from 'utils/stores/mapStore';
import { extractTypeAndParentFromItemID } from 'utils/mapStoreFN/mapStoreFN_items';
import { useParams } from 'react-router-dom';
import { deepClone } from 'utils/helpers/deepClone';
import { getStyleHotkeyMatch, themeStore } from 'utils/stores/themeStore';
import useESC from 'utils/hooks/hotKeys/useESC';
import { setNextStyleValue } from 'utils/mapStoreFN/mapStoreFN_style';
import { hotKeysStore } from 'utils/stores/hotKeysStore';
import {
  ActionHotkeyValues,
  CustomEditor,
  CustomElement,
  CustomText,
  cFORMATHOTKEYS,
  cSLATE_ACTION_HOTKEYS,
} from './slatetypes';
import { Elements, Leaf } from './slateComponent';
import { isHotkey } from 'is-hotkey';
import { slateActions, toggleMarkorNode } from './slateUtils';
import { IconInvalidLink, UnselectedText } from 'components/icon/slateIcons';
import { cSLATE_PLACEHOLDER } from 'utils/stores/constants';
import { NoteItemData } from 'utils/stores/types';
import { EditingItemState } from 'CoreComponents/Note/Note';
import { Action } from 'CoreComponents/Item/Item';
import { getNumberOfLines } from 'CoreComponents/resize/resizable';
import { areaStateStore } from 'utils/stores/components/areaStore';
import { creatingAreaMode } from 'utils/stores/mapMode/modesStore';
import { setIsEditing } from 'utils/stores/gestureStore';

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

let placeHolder;

function getSlatePlaceHolder(itemID: string, mapID: string): Descendant[] {
  if (creatingAreaMode.areaAndStack) {
    placeHolder = 'Headline Stack';
    return [
      {
        type: 'Paragraph',
        children: [
          {
            text: 'Headline Stack',
          },
        ],
      },
    ];
  }
  placeHolder =
    cSLATE_PLACEHOLDER[extractTypeAndParentFromItemID(itemID).itemType];
  return [
    {
      type: 'Paragraph',
      children: [
        {
          text: placeHolder,
        },
      ],
    },
  ];
}

type SlateEditorProps = {
  readOnly: boolean;
  parentID: string;
  item: NoteItemData;
  onFinishedEditing: () => void;
  onStartEditing: () => void;
  editing: EditingItemState;
  updateNoteState: React.Dispatch<Action>;
  itemRef: React.MutableRefObject<HTMLElement>;
  isResizing: boolean;
  containedInArea: string;
};

const SlateEditor = ({
  readOnly,
  parentID,
  item,
  onFinishedEditing,
  onStartEditing,
  itemRef,
  containedInArea,
  updateNoteState,
}: SlateEditorProps) => {
  const [editor] = useState(() => withReact(createEditor()));

  editor.isInline = ({ type }) => type === 'Link';

  const { dispatch } = useESC();
  const mapId = useParams().mapId;

  const [slateText, setSlateText] = useState<Descendant[]>(
    item?.content?.slateContent || getSlatePlaceHolder(item.itemId, mapId)
  );

  const noteType = extractTypeAndParentFromItemID(item.itemId);

  const shortcutCombo = e => {
    const modifiers = ['Meta', 'Alt', 'Control', 'Shift'];

    const isCombo = !!(
      (e.metaKey || e.shiftKey || e.ctrlKey || e.altKey) &&
      !modifiers.includes(e.key)
    );

    if (!isCombo) return false;

    let combo = [];
    const allowedKeys = Object.values(hotKeysStore.itemHotkeys).map(
      hotkey =>
        hotkey.triggerOnEditing &&
        (hotkey.shortcut || hotkey.alternativeShortcut)
    );

    // Check for Meta or Ctrl and add 'mod' to the combo array
    if (e.metaKey || e.ctrlKey) combo.push('mod');

    // Check for Shift and add 'shift' to the combo array
    if (e.shiftKey) combo.push('shift');

    // Check for Alt and add 'alt' to the combo array
    if (e.altKey) combo.push('alt');

    const newCombo = [
      `${combo.join('+')}+${e.key}`,
      `${combo.join('+')}+${e.code.replace('Key', '').toLowerCase()}`,
    ];

    const isComboAllowed = newCombo.some(combo => allowedKeys.includes(combo));

    return isComboAllowed;
  };

  const isEmptySlate = () => {
    const getTextContent = editor.string([]);

    return getTextContent === '' || getTextContent === placeHolder;
  };

  const onFinishEditing = (slateContent?: Descendant[]) => {
    if (isEmptySlate()) {
      deleteContent(mapId, {
        idToRemove: [item.itemId],
      });
    } else {
      //prevent unneccessary valtio store update if editor state was not changed
      const prevTextValue = item.content.textContent;
      const currentTextValue = editor.string([]);
      const wasEdited = prevTextValue !== currentTextValue;

      if (wasEdited) {
        let textWrap = false;
        if (!item.style.customWidth && itemRef) {
          const textLines = getNumberOfLines(itemRef);
          textWrap = textLines > 1;
        }
        if (containedInArea !== 'none' && noteType.itemType === 'Default') {
          areaStateStore.store.task.push({
            actions: 'Edited',
            targetAreaID: containedInArea,
            stackid: parentID,
            options: {
              element: itemRef.current,
            },
          });
          areaStateStore.store.state = 'Execute';
        }

        const noteHeight = itemRef.current.clientHeight;

        handleChangeTextAndNoteWidth(
          parentID,
          item.itemId,
          slateContent || slateText,
          mapId,
          textWrap,
          noteHeight || 0
        );
      }
    }
    Transforms.deselect(editor);
    setIsEditing(false);
    onFinishedEditing();
    return;
  };

  const renderElement = useCallback(
    (props: RenderElementProps) => <Elements {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );

  useEffect(() => {
    if (item.content.textContent === null) {
      Transforms.select(editor, []);
    }
  }, []);

  return (
    <Slate
      editor={editor}
      initialValue={deepClone(slateText)}
      onChange={e => {
        setSlateText(e);
      }}
    >
      <Editable
        key={readOnly as any}
        readOnly={false}
        style={{
          color: item?.style?.textColor || themeStore.defaultColor,
          fontWeight: item?.style?.formattingOfTheEntireNote?.isBold
            ? 'bold'
            : 'initial',
          fontStyle: item?.style?.formattingOfTheEntireNote?.isItalic
            ? 'italic'
            : 'normal',
          textDecoration: item?.style?.formattingOfTheEntireNote?.isUnderlined
            ? 'underline'
            : '',
          userSelect: 'none',
          borderRadius: (item as NoteItemData)?.style?.border?.isSquared
            ? '0px'
            : '1em',

          width: '100%',
          height: '100%',
          fontSize: `inherit`,
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
        className="focus:outline-none  slate-editor "
        onBlur={() => onFinishEditing()}
        onFocus={() => {
          Transforms.select(editor, Editor.end(editor, []));
          onStartEditing();
        }}
        onPaste={e => {
          e.preventDefault();
          return;
        }}
        // this is only called, when isEditing
        onKeyDown={event => {
          for (const formatHotkey in cFORMATHOTKEYS) {
            if (isHotkey(formatHotkey, event)) {
              event.stopPropagation();

              const mark = cFORMATHOTKEYS[formatHotkey];
              toggleMarkorNode(editor, mark);
              return;
            }
            for (const slateActionHotkey in cSLATE_ACTION_HOTKEYS) {
              if (isHotkey(slateActionHotkey, event)) {
                event.stopPropagation();
                event.preventDefault();

                slateActions(
                  event,
                  cSLATE_ACTION_HOTKEYS[slateActionHotkey],
                  editor,
                  mapId,
                  parentID,
                  item.itemId
                );

                if (
                  (cSLATE_ACTION_HOTKEYS[
                    slateActionHotkey
                  ] as ActionHotkeyValues) !== 'Paste'
                )
                  onFinishEditing(editor.children);

                return;
              }
            }
          }

          if (event.metaKey && event.altKey) {
            const isHotKeyMatch = getStyleHotkeyMatch(`${event.code}`, mapId);
            if (isHotKeyMatch) {
              onFinishEditing();
              updateNoteState({
                target: 'reset',
                payload: 'reset',
              });
            }
          }
          if (!shortcutCombo(event)) event.stopPropagation();

          if (event.key === 'Escape' && !readOnly) {
            onFinishEditing();
            const handleESC = dispatch('slate Editor');
            handleESC(mapId, item);
          }
          if (event.key === 'Tab') {
            event.preventDefault();
            event.stopPropagation();
            event.shiftKey
              ? setNextStyleValue(mapId, 'indentation', 'prev')
              : setNextStyleValue(mapId, 'indentation', 'next');
          }
        }}
        autoFocus
        renderLeaf={props => renderLeaf(props)}
        renderElement={props => renderElement(props)}
      />
    </Slate>
  );
};

export default SlateEditor;
