import QuickAccess from 'PlasmicComponents/QuickAccess';
import {
  dashboardStore,
  toggleQuickAccessModal,
} from 'utils/stores/dashboardStore';

import QuickAccessTopSection from './sidebar/TopSection';
import QuickAccessModalItems from './modal/QuickAccesItems';
import { useHotkeys } from '@mantine/hooks';

export default function QuickAccessModal() {
  const closeModalCompletely = () => {
    dashboardStore.quickAccessModal = false;
  };

  useHotkeys([['Escape', closeModalCompletely]]);

  return (
    <div
      onClick={toggleQuickAccessModal}
      className="fixed z-50 w-full h-full flex justify-center"
    >
      <div
        style={{
          zIndex: 100,
          margin: 'auto',
        }}
        className="h-fit max-h-[80vh]"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <QuickAccess
          topSectionSlot={<QuickAccessTopSection isInModal />}
          quickAccessItems={<QuickAccessModalItems />}
          isModal
        />
      </div>
    </div>
  );
}
