import React, { useEffect, useReducer, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useParams } from 'react-router-dom';

import {
  ImageItemData,
  ItemData,
  NoteItemData,
  ParentType,
} from '../../utils/stores/types';
import { DraggedItem } from 'utils/hooks/useDropOnCanvas';
import { updateLineConnectionCord } from 'utils/mapStoreFN/mapStoreFN_connection';
import connectItemsStore, {
  ConnectingNotesValue,
} from 'utils/stores/connectingItemStore';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';
import Border from 'components/Border/Border';

import StackItemPlaceholder from 'PlasmicComponents/StackItemPlaceholder';
import BackgroundBlurWrapper from '../SlateEditor/BackgroundBlurWrapper';
import {
  extractTypeAndParentFromItemID,
  handleDropItemsWithMultiSelect,
  handleDropOnItem,
} from 'utils/mapStoreFN/mapStoreFN_items';
import { updateMapMode } from 'utils/stores/mapmodesStore';
import Note from '../Note/Note';
import { themeStore } from 'utils/stores/themeStore';
import { Resize } from 'CoreComponents/resize/resizable';
import { imageTag } from 'utils/stores/constants';
import { setDragging } from 'utils/stores/gestureStore';
import { Checkbox } from 'ShadcnComponents/Checkbox';
import useItemStyleOperations from 'utils/hooks/useItemStyleOperations';
import { insertMode } from 'utils/stores/mapMode/modesStore';
import getSelectedIDs, {
  onSelect,
} from 'utils/helpers/Selection/selectionUtils';
import selectedContentsStore from 'utils/stores/Selection/selectedContentStore';
import { SlateView } from 'CoreComponents/SlateEditor/slateComponent';
import {
  currentMapStateStore,
  handleMultiDragItems,
  setSelected,
} from 'utils/stores/mapStore';
import {
  applyCheckMarkSize,
  applyItemFontSize,
  calculateDropZone,
  toggleEditMode,
} from './noteUtils';
import {
  getNormalizedCoordinates,
  getScaleFromMatrix,
} from 'utils/helpers/Canvas/clamp-zoom';
import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';

export type ItemProps = {
  item: NoteItemData | ImageItemData;
  parentID: string;

  selectoRef?: React.MutableRefObject<any>;
  isContainedInArea: string;
  totalItemsInStack: number;
  itemIndexPosition: number;
  lastInteraction: Date;
  isTitleNote?: boolean;
  insertModeActive: boolean;
  parentPostion: { x: number; y: number };

  isSelected: boolean;
  parentType: ParentType;
  useSelectionV2: boolean;
  parentLastInteraction: Date;
};

type EditingNotes = 'yes' | 'no' | undefined;

type NoteStates = {
  noteContent: string | null;
  editingNote: EditingNotes;
  isResizing: boolean;
  isDraggingNote: boolean;
};

type Target = 'resizing' | 'editingNote' | 'dragging' | 'reset';

export type Action = {
  target: Target;
  payload: 'yes' | 'no' | boolean | 'reset';
};

function resetNoteState(noteContent: string): NoteStates {
  return {
    noteContent,
    editingNote: 'no',
    isDraggingNote: false,
    isResizing: false,
  };
}

function reducer(state: NoteStates, action: Action): NoteStates {
  const resetNote = resetNoteState(state.noteContent);

  switch (action.target) {
    case 'dragging': {
      return {
        ...resetNote,

        isDraggingNote: action.payload as boolean,
      };
    }
    case 'editingNote': {
      if (state.isDraggingNote) return state;
      return {
        ...resetNote,
        editingNote: action.payload as EditingNotes,
      };
    }
    case 'resizing': {
      return {
        ...resetNote,
        isResizing: action.payload as boolean,
      };
    }
    case 'reset': {
      return resetNote;
    }
  }
}

export const Item = ({
  item,
  parentID,
  selectoRef,
  isContainedInArea,
  totalItemsInStack,
  itemIndexPosition,
  isSelected,
  insertModeActive,
  useSelectionV2,
  parentLastInteraction,
}: ItemProps) => {
  const mapId = useParams().mapId!;

  const itemRef = React.useRef<HTMLDivElement>(null);

  // This here for rendering sake, to allow the insert placeholder item change
  const [dropZone, setDropZone] = useState<'above' | 'below'>('above');

  const [{ isDraggingNote, isResizing, editingNote }, dispatch] = useReducer(
    reducer,
    {
      noteContent: (item as NoteItemData)?.content?.textContent,
      editingNote: 'no',
      isDraggingNote: false,
      isResizing: false,
    }
  );

  const itemType = extractTypeAndParentFromItemID(item.itemId);

  const { applyFade } = useItemStyleOperations(mapId);

  // const itemEl = document.querySelector(`selectable-target, [data-item-id="${item.itemId}"]`)

  const [{ isDragging }, drag] = useDrag(
    () => ({
      canDrag: !isResizing && editingNote !== 'yes',
      previewOptions: {},
      type: 'Item',

      item: () => {
        const selectedIDs = getSelectedIDs();
        // const isSelectedNote = useSelectionV2 ? _isSelected : isSelected;

        dispatch({
          target: 'dragging',
          payload: true,
        });

        if (selectedIDs.length > 1 && isSelected) {
          setDragging(true, true);
        } else {
          setDragging(true, false);
        }

        if (!isSelected) {
          setSelected(
            {
              contentID: item.itemId,
              type: 'Note',
              parentType: 'STACK',
              parentID: parentID,
            },
            mapId
          );
        }

        if (hotKeyStateStore.current.isAltActive) {
          updateMapMode('duplicate', true);
        }

        if (!hotKeyStateStore.current.isModActive) {
          const newConnection: ConnectingNotesValue = {
            mapID: mapId,
            isDragging: true,
            connectPairs: [
              {
                itemId: item.itemId,
                position: { x: 0, y: 0 },
              },
              null,
            ],
            type: hotKeyStateStore.current.isAltActive ? 'arrow' : 'line',
            connectionColor: item.style?.border?.color,
          };
          connectItemsStore.connectNotes = newConnection;
          connectItemsStore.creatingConnection = true;
        }

        const draggedItem: DraggedItem = {
          item,
          parentID,
          itemRef: itemRef.current,
          isContainedInArea,
          createDuplicate: hotKeyStateStore.current.isAltActive,
          totalItemsInStack,
          type: item.type,
          id: item.itemId,
          itemIndexPosition,
          height: itemRef.current.clientHeight,
          width: itemRef.current.clientWidth,
          initialOffset: {
            x: itemRef.current.clientLeft,
            y: itemRef.current.clientTop,
          },
          style: item.style,
        };
        return draggedItem;
      },
      collect: monitor => ({ isDragging: !!monitor.isDragging() }),
      end: (item, monitor) => {
        connectItemsStore.connectNotes.isDragging = !monitor.isDragging();
        setDragging(false, false);
      },
    }),
    [item, isResizing, editingNote, isSelected]
  );

  //make droppable
  const [{ hovered, incomingItem }, drop] = useDrop(() => ({
    accept: ['Item'],
    hover: (_, monitor) => {
      const dropTargetRect = itemRef.current?.getBoundingClientRect();
      const dropPosition = monitor.getClientOffset();

      if (dropTargetRect && dropPosition) {
        // Use the helper function to calculate the drop zone
        const localDropZone = calculateDropZone(dropTargetRect, dropPosition);

        setDropZone(localDropZone);
      }
    },
    drop: (incomingItem: DraggedItem, monitor) => {
      const selectedItems = getSelectedIDs();
      if (incomingItem.item.itemId === item.itemId) return;
      // if (hotKeyStateStore.current.isAltActive) return;
      if (insertMode.isActive) {
        // Make drop in stack only when modKey is active and insertMode is true

        const selectedItems = getSelectedIDs();
        const dropTargetRect = itemRef.current?.getBoundingClientRect();
        const dropPosition = monitor.getClientOffset();

        const localDropZone = calculateDropZone(dropTargetRect, dropPosition);
        if (
          selectedItems.length > 1 &&
          selectedItems.includes(incomingItem.id)
        ) {
          handleDropItemsWithMultiSelect(
            parentID,
            {
              note: item,

              dropPosition: localDropZone,
            },
            mapId,
            incomingItem
          );

          return;
        }

        handleDropOnItem(
          parentID,
          {
            note: item,
            noteIndex: itemIndexPosition,
            isContainedInArea,
            dropPosition: localDropZone,
          },
          mapId,
          incomingItem
        );
        return;
      } else if (incomingItem.parentID === parentID) {
        // If the item is placed on an item of the same stack, just drop on canvas
        // Handle multiple item/area drag and drop

        if (selectedItems?.length <= 1) return;

        // Dropping multiple items on the canvas from the same stack
        const canvasTransform =
          currentMapStateStore[mapId].canvas?.CanvasTransform;
        const posDiff = monitor.getDifferenceFromInitialOffset();
        const DraggedItemPosition = monitor.getInitialSourceClientOffset();
        const { x, y } = getNormalizedCoordinates(
          canvasTransform,
          DraggedItemPosition.x,
          DraggedItemPosition.y
        );
        const zoomLevel = getScaleFromMatrix(new Matrix(canvasTransform));

        handleMultiDragItems(
          { x: posDiff.x, y: posDiff.y },
          zoomLevel,
          mapId,
          (incomingItem as DraggedItem).createDuplicate,
          incomingItem,
          undefined,
          {
            x,
            y,
          }
        );
        updateMapMode('droppingMultiSelected', true);
        setDragging(false, false);
        return;
      } else {
        // To make connections
        if (
          connectItemsStore.connectNotes.connectPairs[0]?.itemId !== item.itemId
        ) {
          connectItemsStore.connectNotes.connectPairs[1] = {
            itemId: item.itemId,
            position: {
              x: 0,
              y: 0,
            },
          };
        }
      }
    },
    collect: monitor => {
      const monitorItem = monitor.getItem() as DraggedItem | null;

      return {
        incomingItem: monitorItem,
        hovered:
          monitorItem?.item?.itemId === item?.itemId
            ? false
            : monitor.isOver({ shallow: true }),
      };
    },
  }));

  useEffect(() => {
    if (item?.connections?.length > 0) {
      updateLineConnectionCord(mapId, item.connections, item.itemId);
    }
  }, [item.lastInteraction, parentLastInteraction]);

  const renderSlateEditor = toggleEditMode(
    (item as NoteItemData)?.content?.textContent,
    item.newNote,
    item.type,
    editingNote
  );
  return (
    <div
      ref={el => {
        // allow drag and drop of item when used as default
        if (itemType.itemType === 'Default') {
          !isDragging && drop(el);

          drag(el);
        }

        itemRef.current = el;
      }}
      // prevent any other noteType from being drag
      {...(itemType.itemType === 'Default'
        ? { 'data-drag': `${item.itemId}` }
        : {})}
      onClick={e => {
        e.stopPropagation();
      }}
      onDragStart={e => {
        e.preventDefault();
      }}
      className={`${isDragging && 'opacity-30'} `}
    >
      {insertModeActive && hovered && dropZone === 'above' && (
        <StackItemPlaceholder
          itemWidth={
            (incomingItem as DraggedItem).item.style.customWidth ||
            themeStore.defaultItemWidth
          }
          style={{
            marginBottom: '12px',
          }}
        />
      )}

      <BackgroundBlurWrapper
        // enabled={true}
        enabled={insertModeActive}
        borderColor={item.style?.border?.color || null}
        // key={item.itemId}
      >
        <Resize
          TargetComponent={item}
          editingState={editingNote}
          parentID={parentID}
          updateNoteState={dispatch}
          isResizing={isResizing}
          itemRef={itemRef}
          type={
            (item as NoteItemData).content.textContent === imageTag
              ? 'Image'
              : 'Note'
          }
          mapID={mapId}
        >
          <Border
            isSelected={isSelected}
            style={{
              ...item?.style,
            }}
            height={item.style?.height || itemRef.current?.clientHeight || 0}
            isImage={(item as NoteItemData)?.content?.textContent === imageTag}
            parentID={item.itemId}
            insertMode={insertModeActive}
            type="Item"
            isEditing={editingNote}
            readOnly={false}
            noteType={itemType.itemType}
            connecting={
              !insertModeActive &&
              connectItemsStore.creatingConnection &&
              hovered
            }
          >
            <div
              style={{
                textAlign: item.style?.textAlignment,
                fontSize: `${applyItemFontSize(item)}px`,
                width: '100%',
                height: '100%',
                gap: item?.style?.isTask
                  ? `${8 + Math.floor((applyItemFontSize(item) - 16) / 16) * 4}px`
                  : undefined,
              }}
              id={item.itemId}
              data-index={itemIndexPosition}
              data-parentid={parentID}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();

                if (isDraggingNote) {
                  dispatch({
                    target: 'dragging',
                    payload: false,
                  });
                  return;
                }

                const selected = getSelectedIDs();

                if (editingNote === 'yes') {
                  return;
                }

                if (!e.metaKey && isSelected) {
                  if (
                    selectedContentsStore.getLength() === 1 &&
                    (item as NoteItemData).content?.textContent !== '_IMAGE'
                  ) {
                    dispatch({
                      target: 'editingNote',
                      payload: 'yes',
                    });
                    return;
                  }

                  onSelect(
                    e,
                    isSelected,
                    {
                      contentID: item.itemId,
                      type: 'Note',
                      parentID: parentID,
                      parentType: 'STACK',
                    },
                    selectoRef
                  );

                  return;
                } else if (!e.metaKey && isSelected && !useSelectionV2) {
                  if (
                    selected?.length === 1 &&
                    (item as NoteItemData).content?.textContent !== '_IMAGE'
                  ) {
                    dispatch({
                      target: 'editingNote',
                      payload: 'yes',
                    });
                  }

                  return;
                }

                onSelect(
                  e,
                  isSelected,
                  {
                    contentID: item.itemId,
                    type: 'Note',
                    parentID: parentID,
                    parentType: 'STACK',
                  },
                  selectoRef
                );
              }}
              // prevent any other note type to be selected using box-selection
              className={`${
                itemType.itemType === 'Default' &&
                'selectable-target selectable'
              } ${item?.style?.isTask && 'flex items-center space-x-2'} ${item?.style?.faded && 'opacity-30'}`}
            >
              {item?.style?.isTask && (
                <div
                  contentEditable={false}
                  onMouseDown={e => {
                    e.stopPropagation();
                  }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  className={` ml-1 flex justify-center hover:scale-[1.1] duration-300 transition-transform`}
                >
                  <Checkbox
                    checked={item.style.faded}
                    onCheckedChange={() => {
                      applyFade(item.itemId);
                    }}
                    checkBoxSize={applyCheckMarkSize(item)}
                    // disabled={!item.style.faded}
                    className={` data-[state=checked]:bg-white disabled:cursor-default !rounded-full max-w-[50px] max-h-[50px]`}
                    style={{
                      width: `${applyItemFontSize(item)}px`,
                      height: `${applyItemFontSize(item)}px`,
                    }}
                  />
                </div>
              )}

              <SlateView
                slateContent={(item as NoteItemData).content.slateContent || []}
                item={item}
                hide={renderSlateEditor}
              />

              {renderSlateEditor && (
                <Note
                  isResizing={isResizing}
                  updateNoteState={dispatch}
                  editingItem={editingNote}
                  item={item as NoteItemData}
                  parentID={parentID}
                  itemRef={itemRef}
                  containedInArea={isContainedInArea}
                />
              )}
            </div>
          </Border>
        </Resize>
      </BackgroundBlurWrapper>

      {hovered && insertModeActive && dropZone === 'below' && (
        <StackItemPlaceholder
          itemWidth={
            (incomingItem as DraggedItem).item.style.customWidth ||
            themeStore.defaultItemWidth
          }
          style={{
            marginTop: '12px',
          }}
        />
      )}
    </div>
  );
};

function shouldRender(prev: ItemProps, next: ItemProps) {
  if (prev.item.lastInteraction !== next.item.lastInteraction) {
    return false;
  }
  if (prev.parentLastInteraction !== next.parentLastInteraction) {
    return false;
  }
  if (prev.isSelected !== next.isSelected) {
    return false;
  }
  if (prev.insertModeActive !== next.insertModeActive) {
    return false;
  }
  return false;
}

const ItemMemo = React.memo(Item, shouldRender);
export default ItemMemo;
