import {
  PersistedClient,
  Persister,
} from '@tanstack/react-query-persist-client';
import { createStore, del, get, keys, set, UseStore } from 'idb-keyval';
import { ProxyPersistStorageEngine } from 'valtio-persist';

export async function storage(
  db: UseStore
): Promise<ProxyPersistStorageEngine> {
  return {
    getItem: (name: string) => {
      const g = get(name, db);
      return g == undefined ? null : g;
    },
    setItem: (name, value) => {
      return set(name, value, db);
    },
    removeItem: name => {
      del(name, db);
    },
    getAllKeys: () => {
      return keys(db);
    },
  };
}
export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
  let store = createStore('keyval-store', 'keyval');
  return {
    persistClient: async (client: PersistedClient) => {
      try {
        let allowTransaction = true;

        for (const query of client?.clientState?.queries || []) {
          if (query?.state?.data === undefined) {
            allowTransaction = false;
            break;
          }
        }

        if (allowTransaction) await set(idbValidKey, client, store);
      } catch (e) {
        // console.log('~ persistClient ERROR', e);
        createIDBPersister();
      }
    },
    restoreClient: async () => {
      try {
        return await get<PersistedClient>(idbValidKey, store);
      } catch (e) {
        // console.log('~ restoreClient ERROR', e);
      }
    },
    removeClient: async () => {
      try {
        await del(idbValidKey, store);
      } catch (e) {
        // console.log('~ removeClient ERROR', e);
      }
    },
  } as Persister;
}
