import { useState, useEffect, useRef } from 'react';

const ScalableWrapper = ({
  children,
  scale = 1,
  dynamicScaling = false,
  scaleStep = 0.001,
}) => {
  const [currentScale, setCurrentScale] = useState(scale);
  const wrapperRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (!wrapperRef.current || !contentRef.current) return;

      const contentWidth = contentRef.current.offsetWidth;
      const contentHeight = contentRef.current.offsetHeight;
      const windowWidth = window.innerWidth;

      if (dynamicScaling) {
        // Calculate new scale based on window width, using scaleStep to control the rate of change
        let newScale = 1 + (windowWidth - contentWidth) * scaleStep;

        // Ensure the scale is within the specified range
        // Using 0.1 as the minimum scale to prevent content from disappearing
        newScale = Math.max(0.1, Math.min(newScale, scale));

        setCurrentScale(newScale);
      } else {
        setCurrentScale(scale);
      }

      // Adjust wrapper dimensions to match the scaled content
      wrapperRef.current.style.width = `${contentWidth * currentScale}px`;
      // wrapperRef.current.style.height = `${contentHeight * currentScale}px`;
    };

    handleResize();

    if (dynamicScaling) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [scale, dynamicScaling, scaleStep, currentScale]);

  return (
    <div
      ref={wrapperRef}
      // className="overflow-hidden"
      style={{
        width: 'fit-content',
        height: 'fit-content',
        display: 'inline-block',
      }}
    >
      <div
        ref={contentRef}
        className="transition-transform duration-300 ease-in-out origin-top-left"
        style={{
          transform: `scale(${currentScale})`,
          width: 'fit-content',
          height: 'fit-content',
          transformOrigin: 'top left',
          display: 'inline-block',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScalableWrapper;
