import { ItemData, StackData } from '../stores/types';
import {
  getLastSeletedID,
  getSelectedNoteIDAndStackAtPosition,
  setSelected,
} from 'utils/stores/mapStore';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';

export function handlePressArrowToUpdateSelected(
  e: KeyboardEvent,
  currentSelectedItems: any,
  currentStackState: any,
  mapId: string
) {
  if (
    hotKeyStateStore.current.isModActive ||
    hotKeyStateStore.current.isAltActive ||
    hotKeyStateStore.current.isShiftActive
  )
    return;
  if (currentSelectedItems.length > 0) {
    const currentSelectedItem = getSelectedNoteIDAndStackAtPosition(
      mapId,
      currentSelectedItems,
      'LAST'
    ).itemID;

    const filteredStack = currentStackState.stacks.filter(
      (stack: StackData) => {
        return stack.items.find(i => i.itemId === currentSelectedItem);
      }
    );
    const currentStack: StackData = filteredStack[0];

    const currentSelectedItemIndex = currentStack.items.findIndex(
      (item: ItemData) => item.itemId === currentSelectedItem
    );

    const nextSelectedItem =
      e.key === 'ArrowDown'
        ? currentStack.items[
            (currentSelectedItemIndex + 1) % currentStack.items.length
          ]
        : currentStack.items[
            (currentSelectedItemIndex + currentStack.items.length - 1) %
              currentStack.items.length
          ];

    setSelected(
      {
        contentID: nextSelectedItem.itemId,
        type: 'Note',
        parentID: currentStack.stackId,
        parentType: 'STACK',
      },
      mapId
    );
  }
}
