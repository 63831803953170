import { Note } from 'CoreComponents/Item/noteUtils';
import { ItemData, ConnectionID, StackData, Name } from 'utils/stores/types';
import { v4 as uuidv4 } from 'uuid';

/**
 * Represents a stack of items with various properties and methods.
 *
 * @implements {StackData}
 */
export class Stack {
  /**
   * Indicates if the stack is contained in a specific area.
   * @type {string}
   */
  isContainedInArea: string;

  /**
   * Unique identifier for the stack.
   * @type {string}
   */
  stackId: string;

  /**
   * Position of the stack in 2D space.
   * @type {{ x: number; y: number }}
   */
  position: { x: number; y: number };

  /**
   * Array of items contained in the stack.
   * @type {ItemData[]}
   */
  items: ItemData[];

  /**
   * Array of connection IDs associated with the stack.
   * @type {ConnectionID[]}
   */
  connections: ConnectionID[];

  /**
   * Indicates if the stack is a title note.
   * @type {boolean}
   */
  isTitleNote: boolean;

  /**
   * Timestamp of the last interaction with the stack.
   * @type {Date}
   */
  lastInteraction: Date;

  /**
   * Timestamp of the last synchronization of the stack.
   * @type {Date | undefined}
   */
  lastSync?: Date;

  /**
   * Indicates if the stack is marked for deletion.
   * @type {boolean}
   */
  delete: boolean;
  /**
   * Indicates the components name
   * @type {Name};
   */
  name: Name;

  /**
   * Optional map information associated with the stack.
   * @type {{ mapId: string; name: string } | undefined}
   */
  map?: {
    mapId: string;
    name: string;
  };

  /**
   * Creates an instance of Stack.
   *
   * @param {Object} position - The position of the stack.
   * @param {number} position.x - The x-coordinate of the stack.
   * @param {number} position.y - The y-coordinate of the stack.
   * @param {string} [stackID] - The unique identifier for the stack.
   * @param {ItemData[]} [item] - The items to be contained in the stack.
   * @param {boolean} [isTitleNote] - Indicates if the stack is a title note.
   * @param {string} [isContainedInArea='none'] - Indicates if the stack is contained in a specific area.
   *
   */
  constructor(
    position: { x: number; y: number },
    stackID?: string,
    item?: ItemData[],
    isTitleNote?: boolean,

    isContainedInArea: string | 'none' = 'none'
  ) {
    {
      this.stackId = stackID || 'stack_' + uuidv4();
      this.position = position;
      this.items = item || [new Note(true) as ItemData];
      this.lastInteraction = new Date();
      this.lastSync = new Date();
      this.delete = false;
      this.isContainedInArea = isContainedInArea || 'none';
      this.name = 'STACK';
    }
  }
}
