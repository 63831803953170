import { Matrix } from 'CoreComponents/Canvas/canvas';
import { Resizable } from 're-resizable';
import { useState } from 'react';
import { getScaleFromMatrix } from 'utils/helpers/Canvas/clamp-zoom';

import { updateAreaSize } from 'utils/mapStoreFN/mapStoreFN_areas';

import { addItemToSelected, currentMapStateStore } from 'utils/stores/mapStore';

import { AreaProps } from './Area';

function AreaResize(
  props: AreaProps & {
    mapId: string;
    areaRef: HTMLDivElement;
    children: React.ReactNode;
  }
) {
  const [dragScale, setDragScale] = useState(1);
  const getDragScale = () => {
    const canvaState = currentMapStateStore[props.mapId];
    const currentZoomLevel = getScaleFromMatrix(
      new Matrix(canvaState.canvas.CanvasTransform)
    );

    setDragScale(currentZoomLevel);
    return;
  };

  return (
    <Resizable
      scale={dragScale}
      boundsByDirection={true}
      enable={{
        top: false,
        left: false,
        right: true,
        bottom: true,
        bottomRight: true,
      }}
      size={{
        width: props.size.width,
        height: props.size.height,
      }}
      onResizeStart={() => {
        getDragScale();

        if (!props.isSelected) {
          addItemToSelected(
            {
              contentID: props.areaId,
              type: 'Area',
              parentType: 'AREA',
              parentID: props.areaId,
            },
            props.mapID
          );
        }

        return;
      }}
      onResizeStop={(event, direction, elementRef, delta) => {
        updateAreaSize(
          {
            width: props.size.width + delta.width,
            height: props.size.height + delta.height,
          },
          props.areaId,
          props.mapId
        );
      }}
      minWidth={`${(props?.style?.fontSize || 12) + 20}`}
    >
      {props.children}
    </Resizable>
  );
}

export default AreaResize;
