import { Query, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { FlagProvider } from '@unleash/proxy-client-react';
import 'animate.css';
import axios from 'axios';
import { AppProvider, AuthProvider } from 'propro-common-components';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { unleashConfig } from 'utils/unleashConfig';

import App from './app/app';

import { createIDBPersister } from './utils/storage';
import registerGlobalShortcuts from './utils/tauri/registerGlobalShortcuts';
// const Settings = require('settings.json');
import { axiosErrorHandlingInterceptor } from 'api/AppError';
import Settings from 'settings.json';

const container = document.getElementById('root');
const root = createRoot(container);

// global shortcut registration
registerGlobalShortcuts();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // gcTime: 1000 * 60 * 0.5, // 30 sec
      // staleTime: 1000,

      retry: 3,
      refetchOnMount: 'always',
      // refetchOnWindowFocus: 'always',
      gcTime: 1000 * 60 * 60 * 24, // 24 hours,
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
    },
    dehydrate: {
      // shouldDehydrateQuery: (query: Query) => false,
      shouldDehydrateQuery: (query: Query) =>
        JSON.stringify(query.queryKey) === JSON.stringify(['folder structure']),
    },
  },
});

axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;
axios.defaults.withCredentials = true;
axiosErrorHandlingInterceptor();

root.render(
  <StrictMode>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: createIDBPersister(), buster: 'v1' }}
    >
      <ReactQueryDevtools initialIsOpen={false} />
      <AppProvider baseURL={Settings[process.env.NODE_ENV].server_url}>
        <AuthProvider>
          <FlagProvider config={unleashConfig}>
            <App />
          </FlagProvider>
        </AuthProvider>
      </AppProvider>
    </PersistQueryClientProvider>
  </StrictMode>
);

const welcome = () => console.log('Welcome to MapMap 🚀');
welcome();
