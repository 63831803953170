import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Body, fetch, FormInput } from '@tauri-apps/api/http';
import { WebviewWindow } from '@tauri-apps/api/window';
import axios from 'axios';
// import axiosTauriAdapter from 'axios-tauri-adapter';
import { RUNNING_IN_TAURI, useMutate } from 'propro-common-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Settings from 'settings.json';
import uploadUrl from 'utils/helpers/getImageUrl';
import { updateCmcsAndCmssWithLoadedMapData } from 'utils/stores/mapStore';
import { FolderData, MapData } from 'utils/stores/types';

export function useLogin(
  signInMode: boolean,
  callback: (res) => Promise<void> = () => null
) {
  const BASE_URL = Settings[process.env.NODE_ENV].server_url;
  const ENDPOINT = signInMode
    ? '/api/login?returnTokens=true'
    : '/api/register?returnTokens=true';
  // const BASE_URL = 'http://localhost:8080';
  // const ENDPOINT = '/api';

  return useMutation({
    mutationFn: async (data: FormInput) => {
      axios.defaults.baseURL = BASE_URL;

      let res;

      console.log('Here 1');
      console.log('calling inside browser: ', RUNNING_IN_TAURI);
      if (RUNNING_IN_TAURI) {
        console.log('Here 2');
        const body = Body.form(data);
        res = await fetch(BASE_URL + ENDPOINT, {
          method: 'POST',
          body,
        });
        console.log(res);

        console.log('Here 3');
        if (!res?.ok) throw new Error(`${res?.data?.message}`, res);
        console.log('Here 4');
        return res.data;
      }
      console.log('Here 5');
      res = await axios.post(ENDPOINT, data);
      console.log('Here 6');
      return res.data;
    },
    onError(error) {
      console.log('Fail 1', error);
      toast(`${error}`);
    },
  });
}

export const useCreateMap = (redirect: boolean, folderId?: string) => {
  // const BASE_URL = Settings[process.env.NODE_ENV].server_url;
  // const ENDPOINT = folderId ? `/api/folder/${folderId}/map` : '/api/map';

  // return useMutation({
  //   mutationFn: async (data: FormInput) => {
  //     axios.defaults.baseURL = BASE_URL;

  //     let res;

  //     console.log('Here 1');

  //     if (RUNNING_IN_TAURI) {
  //       console.log('Here 2');
  //       Object.keys(data).forEach(key => {
  //         if (key === 'lastInteraction' || key === 'lastSync') {
  //           const newVal = new Date(data[key]);
  //           data[key] = newVal.toISOString();
  //         } else if (typeof data[key] === 'object') {
  //           data[key] = JSON.stringify(data[key]);
  //         }
  //         console.log('Data key => ', key, typeof data[key]);
  //       });

  //       const body = Body.json(data);
  //       console.log('Here 2.5', body);
  //       res = await fetch(BASE_URL + ENDPOINT, {
  //         method: 'POST',
  //         body,
  //         headers: {
  //           // ...(axios?.defaults?.headers || {}),
  //           Accept: 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json',
  //           Authorization: axios?.defaults?.headers?.Authorization,
  //         },
  //       } as FetchOptions).catch(console.error);
  //       console.log(res);

  //       console.log('Here 3');
  //       if (!res?.ok) throw new Error(`${res?.data?.message}`, res);
  //       console.log('Here 4');
  //       return res.data;
  //     }
  //     console.log('Here 5');
  //     res = await axios.post(ENDPOINT, data, {
  //       // adapter: axiosTauriAdapter,
  //     });
  //     console.log('Here 6');
  //     return res.data;
  //   },
  //   onError(error) {
  //     console.log('Fail 1', error);
  //     toast(`${error}`);
  //   },
  // });

  const navigate = useNavigate();

  return useMutate({
    url: folderId ? `/api/folder/${folderId}/map` : '/api/map',
    method: 'POST',
    invalidateAllQueries: true,
    toastMessage: {
      message: 'Creating map',
      successMessage: 'Map created successfully',
      errorMessage: 'Failed to create map',
    },
    onSuccess: redirect
      ? (res: { data: MapData }) => {
          console.log('res', res);
          updateCmcsAndCmssWithLoadedMapData(res.data.mapId, res.data);
          navigate(`/map/${res.data.mapId}`);
        }
      : undefined,
  });
};

export const useCreateMapFromOverlay = (
  redirect: boolean,
  folderId?: string
) => {
  function openInNewWindow(url: string, mapId: string, title) {
    const webview = new WebviewWindow(`${mapId}`, {
      url,
      title: `${title || 'MapMap'}`,
    });
    webview.once('tauri://error', function (e) {
      toast((e?.payload as string) || 'Error opening map');
    });
  }

  return useMutate({
    url: folderId ? `/api/folder/${folderId}/map` : '/api/map',
    method: 'POST',
    invalidateAllQueries: true,
    toastMessage: {
      message: 'Creating map',
      successMessage: 'Map created successfully',
      errorMessage: 'Failed to create map',
    },
    onSuccess: redirect
      ? (res: { data: MapData }) => {
          updateCmcsAndCmssWithLoadedMapData(res.data.mapId, res.data);
          console.log('calling inside browser: ', RUNNING_IN_TAURI);
          if (RUNNING_IN_TAURI) {
            openInNewWindow(
              `/map/${res.data.mapId}`,
              res.data.mapId,
              res.data.name
            );
          } else {
            window.open(`/map/${res.data.mapId}`, '_blank');
          }
        }
      : undefined,
  });
};

// ? This might not stand, has to be reviewed
export const useUpdateMap = () => {
  const queryClient = useQueryClient();

  return useMutate({
    url: '/api/map',
    method: 'POST',
    data: ({ data }) => data,
    toastMessage: {
      message: 'Updating map',
      successMessage: 'Map updated successfully',
      errorMessage: 'Failed to update map',
    },
    onSuccess: (_res, { parentFolder }) => {
      queryClient.invalidateQueries({ queryKey: ['folder structure'] });
      if (parentFolder) {
        queryClient.invalidateQueries({ queryKey: ['folder', parentFolder] });
      }
    },
  });
};

export const useSaveMapToBackend = () => {
  const BASE_URL = Settings[process.env.NODE_ENV].server_url;

  return useMutate({
    url: `${BASE_URL}/api/map`,
    method: 'POST',
    data: (mapData: any) => mapData,
    invalidateAllQueries: true,
    successLog: 'Map saved successfully:',
    errorLog: 'Error saving map:',
    logURL: 'API Endpoint:',
    toastMessage: {
      message: 'Saving map...',
      successMessage: 'Map saved successfully!',
      errorMessage: 'Failed to save map.',
      options: {},
    },
    onSuccess: (data, variables) => {
      console.log('Mutation successful:', data);
      return data;
    },
    onError: (error, variables) => {
      console.error('Mutation error:', error);
    },
  });
};

type DeleteParams = {
  id: string;
};

export const useDeleteMapFromBackend = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, DeleteParams>({
    mutationFn: ({ id }) => axios.delete(`/api/map/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folder structure'] });
      //queryClient.invalidateQueries({ queryKey: ['folder'] });
      //queryClient.invalidateQueries({ queryKey: ['All maps'] });
    },
  });
  /*return useMutate({
    url: !mapId ? '/api/map' : `/api/map/${mapId}`,
    method: 'DELETE',
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folder structure'] });
      queryClient.invalidateQueries({ queryKey: ['All maps'] });
    },
    toastMessage: {
      message: 'Deleting map',
      successMessage: 'Map deleted successfully',
      errorMessage: 'Failed to delete map',
    },
  });*/
};

export const useDeleteMultipleMapsAndFoldersFromBackend = () => {
  const queryClient = useQueryClient();
  return useMutate({
    url: '/api/folder/deleteMapsAndFolders',
    method: 'DELETE',
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folder'] });
      queryClient.invalidateQueries({ queryKey: ['folder structure'] });
      queryClient.invalidateQueries({ queryKey: ['All maps'] });
      queryClient.invalidateQueries({ queryKey: ['All folders'] });
    },
    toastMessage: {
      message: 'Deleting maps and folders',
      successMessage: 'Maps and folders deleted successfully',
      errorMessage: 'Failed to delete maps and folders',
    },
  });
};

export const useCreateFolder = (parentFolderId: string) => {
  const queryClient = useQueryClient();

  return useMutate({
    url: parentFolderId
      ? `/api/folder/${parentFolderId}/subfolder`
      : '/api/folder',
    method: 'POST',
    toastMessage: {
      message: 'Creating folder',
      successMessage: 'Folder created successfully',
      errorMessage: 'Failed to create folder',
    },
    onSuccess: (res: { data: FolderData }) => {
      if (parentFolderId) {
        queryClient.invalidateQueries({ queryKey: ['folder', parentFolderId] });
      }

      queryClient.invalidateQueries({ queryKey: ['folder structure'] });
    },
  });
};

export const useUpdateFolder = () => {
  const queryClient = useQueryClient();

  return useMutate({
    url: ({ folderId }) => `/api/folder/${folderId}`,
    method: 'PUT',
    data: ({ data }) => data,
    toastMessage: {
      message: 'Updating folder',
      successMessage: 'Folder updated successfully',
      errorMessage: 'Failed to update folder',
    },
    onSuccess: (_res, { parentFolder }) => {
      queryClient.invalidateQueries({ queryKey: ['folder structure'] });
      if (parentFolder) {
        queryClient.invalidateQueries({ queryKey: ['folder', parentFolder] });
      }
    },
  });
};

export const useDeleteFolder = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, DeleteParams>({
    mutationFn: ({ id }) => axios.delete(`/api/folder/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folder structure'] });
      queryClient.invalidateQueries({ queryKey: ['folder'] });
      queryClient.invalidateQueries({ queryKey: ['All folders'] });
    },
  });

  /*return useMutate({
    url: `/api/folder/${folderId}`,
    method: 'DELETE',
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folder structure'] });
      queryClient.invalidateQueries({ queryKey: ['All folders'] });
    },
    toastMessage: {
      message: 'Deleting Folder',
      successMessage: 'Folder deleted successfully',
      errorMessage: 'Failed to delete folder',
    },
  });*/
};

// Not being used in the app right now. when needed implement with useMutate.

// export const useGarbageCollectStacksAndAreas = () => {
//   const context = useContext(ViewContext);
//   const queryClient = useQueryClient();

//   return useMutation<any, AxiosError, GarbageCollectParams>({
//     mutationFn: async ({ mapId, stackIds, areaIds }: GarbageCollectParams) => {
//       const endpoint = `/api/map/${mapId}/garbageCollectStacksAndArea`;
//       return axios.delete(endpoint, { data: { stackIds, areaIds } });
//     },
//     onSuccess: res => {
//       context.notification.show(res.data.message, 'success', true, 'toast');
//       queryClient.invalidateQueries();
//     },
//     onError: (err: any) => {
//       const message = err.response?.data?.message || 'An error occurred';
//       context.notification.show(message, 'error', false);
//     },
//   });
// };

export function useUpload(mapId: string) {
  return useMutation({
    mutationKey: ['upload-file', mapId],
    mutationFn: async (props: {
      ImageBase64: string;
      oldUrl?: string;
      type?: string;
    }) => await uploadUrl(props),
  });
}

export const useUserLogout = () => {
  return useMutate({
    url: `/api/logout`,
    method: 'POST',
    onSuccess(res: { redirectUrl: string }) {
      // should redirect to login page
      if (res) {
        window.location.href = res.redirectUrl;
      }
    },
    toastMessage: {
      message: 'Logging out',
      successMessage: '',
      errorMessage: 'Failed to logout, Please try again',
    },
  });
};

// export const useAddGlobalStyleShortcut = (id:string) => {
//   const queryClient = useQueryClient()
//   return useMutate({
//     url: `/api/theme/myStyles/shortcuts/${id}`,
//     method: 'PATCH',
//     toastMessage: {
//       message: 'Updating shortcut',
//       successMessage: 'Shortcut updated successfully',
//       errorMessage: 'Failed to update shortcut',
//     },
//     onSuccess: res => {
//       queryClient.invalidateQueries({queryKey: ['My General Style Shortcuts']})
//     },
//   });
// };

// test
// export const useCreateGlobalShortcut = () => {
//   return useMutate({
//     url: `/api/themeShortcut`,
//     method: 'POST',
//     toastMessage: {
//       message: 'Creating style',
//       successMessage: 'Style created successfully',
//       errorMessage: 'Failed to create style',
//     }
//   });
// };

// User Global Styles
export const useAddToMyGlobalStyleShortcuts = () => {
  const queryClient = useQueryClient();
  return useMutate({
    url: `/api/user/globalStyle/shortcuts`,
    method: 'PUT',
    toastMessage: {
      message: 'Adding style shortcut',
      successMessage: 'Style shortcut created successfully',
      errorMessage: 'Failed to create style shortcut',
    },
    onSuccess(res) {
      if (res) {
        queryClient.refetchQueries({
          queryKey: ['My General Style Shortcuts'],
        });
      }
    },
  });
};

export const useUpdateSingleGlobalStyleShortcut = () => {
  const queryClient = useQueryClient();
  return useMutate({
    url: ({ shortcutId }) => `/api/user/globalStyle/shortcuts/${shortcutId}`,
    method: 'PUT',
    data: ({ data }) => data,
    toastMessage: {
      message: 'Updating style shortcut',
      successMessage: 'Style shortcut updated successfully',
      errorMessage: 'Failed to update style shortcut',
    },
    onSuccess(res, { mapId }) {
      if (res) {
        queryClient.invalidateQueries({
          queryKey: ['My General Style Shortcuts'],
        });
      }
    },
  });
};

export const useDeleteFromMyGlobalStyleShortcuts = () => {
  const queryClient = useQueryClient();
  return useMutate({
    url: `/api/user/globalStyle/shortcuts`,
    method: 'DELETE',
    toastMessage: {
      message: 'Deleting style shortcut',
      successMessage: 'Style shortcut deleted successfully',
      errorMessage: 'Failed to delete style shortcut',
    },
    onSuccess(res) {
      if (res) {
        queryClient.invalidateQueries({
          queryKey: ['My General Style Shortcuts'],
        });
      }
    },
  });
};

// Map Styles
export const useAddToMapStyleShortcuts = (mapId: string) => {
  const queryClient = useQueryClient();
  return useMutate({
    url: `/api/map/${mapId}/styleShortcut`,
    method: 'POST',
    toastMessage: {
      message: 'Adding style shortcut',
      successMessage: 'Style shortcut created successfully',
      errorMessage: 'Failed to create style shortcut',
    },
    onSuccess(res) {
      if (res) {
        // Refetch map styles
        queryClient.invalidateQueries({
          queryKey: ['MapFromBackend', mapId],
        });
      }
    },
  });
};

export const useDeleteFromMapStyleShortcuts = () => {
  const queryClient = useQueryClient();
  return useMutate({
    url: ({ mapId, shortcutId }) =>
      `/api/map/${mapId}/styleShortcut/${shortcutId}`,
    method: 'DELETE',
    toastMessage: {
      message: 'Deleting style shortcut',
      successMessage: 'Style shortcut deleted successfully',
      errorMessage: 'Failed to delete style shortcut',
    },
    onSuccess(res, { mapId }) {
      if (res) {
        queryClient.invalidateQueries({
          queryKey: ['MapFromBackend', mapId],
        });
      }
    },
  });
};

export const useUpdateSingleMapStyleShortcut = () => {
  const queryClient = useQueryClient();
  return useMutate({
    url: ({ mapId, shortcutId }) =>
      `/api/map/${mapId}/styleShortcut/${shortcutId}`,
    method: 'PUT',
    data: ({ data }) => data,
    toastMessage: {
      message: 'Updating style shortcut',
      successMessage: 'Style shortcut updated successfully',
      errorMessage: 'Failed to update style shortcut',
    },
    onSuccess(res, { mapId }) {
      if (res) {
        queryClient.invalidateQueries({
          queryKey: ['MapFromBackend', mapId],
        });
      }
    },
  });
};
