import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { useParams } from 'react-router-dom';
import { getScaleFromMatrix } from 'utils/helpers/Canvas/clamp-zoom';
import { currentMapStateStore } from 'utils/stores/mapStore';

import { generalStore } from 'utils/stores/generalStore';

import { useSnapshot } from 'valtio/react';

export let mousePosition: {
  x: number;
  y: number;
} = {
  x: 0,
  y: 0,
};

const CreateArea = ({ hideBox }: { hideBox?: boolean }) => {
  const mapId = useParams().mapId!;
  const {
    mouse: { x, y },
  } = useSnapshot(generalStore.mousePosition);

  mousePosition.x = x;
  mousePosition.y = y;

  const currentMapState = useSnapshot(currentMapStateStore[mapId]);

  const zoomLevel = getScaleFromMatrix(
    new Matrix(currentMapState.canvas?.CanvasTransform)
  );

  return (
    <>
      <AreaText position={{ x: x, y: y }} anchorText={hideBox} />
      {!hideBox && (
        <div
          style={{
            width: `${400 * zoomLevel}px`,
            height: `${400 * zoomLevel}px`,
            border: `2px dashed #f0ead6`,
            position: 'absolute',
            top: y,
            left: x,
            zIndex: '20',
            pointerEvents: 'none',
            display: 'grid',
            placeContent: 'center',
            color: '#f0ead6',
            opacity: '0.7',
          }}
        />
      )}
    </>
  );
};

type AreaTextProps = {
  position: { x: number; y: number };
  anchorText: boolean;
};

/**
 * A ui helper text component
 *
 * @param position
 * -- x:number
 * -- y: number
 * @property {Object} cacheMouse - Cached mouse position
 * @property {number} cacheMouse.x - The x-coordinate of the cached mouse position
 * @property {number} cacheMouse.y - The y-coordinate of the cached mouse position
 *
 * @returns Div element
 */

function AreaText(props: AreaTextProps) {
  const cacheMousePosition = !props.anchorText && props.position;
  return (
    <div
      style={{
        position: 'absolute',
        width: 'fit-content',
        height: 'auto',
        zIndex: '30',
        top: `${cacheMousePosition.y}px`,
        left: `${cacheMousePosition.x}px`,
        pointerEvents: 'none',
        transform: 'translateY(-30px)',
      }}
    >
      <p
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          color: 'white',
          position: 'absolute',
          width: 'max-content',
          fontSize: '12px',
          padding: '2px 8px',
        }}
      >
        Drag to create a custom area - hold alt to add a title note
      </p>
    </div>
  );
}
export default CreateArea;
