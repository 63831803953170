import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';

// import { SettingFilled } from '@ant-design/icons'; // would be used later

import { getEmptyImage } from 'react-dnd-html5-backend';
import { DraggedArea, DraggedItem } from 'utils/hooks/useDropOnCanvas';
import {
  addItemToSelected,
  currentMapStateStore,
  removeItemFromSelected,
} from 'utils/stores/mapStore';
import { themeStore } from 'utils/stores/themeStore';
import { AreaData } from 'utils/stores/types';
import AreaResize from './AreaResize';

// import { useIntersection } from '@mantine/hooks';
import Border from 'components/Border/Border';
import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import { MutableRefObject, memo, useEffect, useRef, useState } from 'react';
import {
  handleCreateItemInArea,
  handleDropOnArea,
} from 'utils/mapStoreFN/mapStoreFN_areas';
import { hotKeyStateStore } from 'utils/stores/hotKeyStatesStore';
import { useSnapshot } from 'valtio';
import { TitleAndStamps } from './IconAndStamps';
import { areaStateStore } from 'utils/stores/components/areaStore';
import { getNormalizedCoordinates } from 'utils/helpers/Canvas/clamp-zoom';
import getSelectedIDs, {
  onSelect,
} from 'utils/helpers/Selection/selectionUtils';
import selectedContentsStore from 'utils/stores/Selection/selectedContentStore';
import { gestureStore, setDragging } from 'utils/stores/gestureStore';
import Selecto from 'react-selecto';

export type AreaProps = Omit<AreaData, 'lastSync?: Date'> & {
  selectoRef: MutableRefObject<any>;
  areaIndex: number;
  isSelected: boolean;
  areaProxy: AreaData;
  useSelectionV2: boolean;
  mapID: string;
};
export default function Area(props: AreaProps) {
  const areaRef = useRef(null);
  // const isVisible = useIntersection(areaRef);
  //   -------states----------

  const canvaState = currentMapStateStore[props.mapID];
  const hotKey = useSnapshot(hotKeyStateStore.current);
  const [canSelect, setCanSelect] = useState(true);

  // using this as opposed to state to avoid rerender
  // this would hold the prev drag state

  //   ------hooks------------

  const AreaProps: DraggedArea = {
    width: props.size.width,
    height: props.size.height,
    defaultTextColor: props.style?.border?.color || themeStore.defaultColor,
    areaId: props.areaId,
    backgroundColor: props.style?.fillColor,
    createDuplicate: hotKey.isAltActive,
    type: 'Area',
    id: props.areaId,
    encompassingStacks: props.encompassingStacks,
    position: props.position,
    style: props.style,
  };
  const [{ isDragging }, drag, preview] = useDrag({
    previewOptions: {},
    type: 'Area',
    item: () => {
      const selected = getSelectedIDs();

      if (selected.length >= 2 && props.isSelected) {
        setDragging(true, true);
      } else {
        setDragging(true, false);
      }

      selectedContentsStore.select({
        contentID: props.areaId,
        type: 'Area',
        parentID: props.areaId,

        parentType: 'AREA',
      });
      setCanSelect(false);
      return AreaProps;
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setDragging(false, false);
    },
  });

  const [{ hovered }, drop] = useDrop(
    () => ({
      accept: ['Item'],
      drop: (incomingItem: DraggedItem, monitor) => {
        const { x, y } = monitor.getInitialSourceClientOffset();

        const posDiff = monitor.getDifferenceFromInitialOffset();
        if (!posDiff) return;

        const itemX = x + posDiff.x;
        const itemY = y + posDiff.y;
        const normalizedPoint = getNormalizedCoordinates(
          currentMapStateStore[props.mapID].canvas.CanvasTransform,
          itemX,
          itemY
        );

        const isMultiDrag =
          gestureStore.Dragging.isDragging && gestureStore.Dragging.isMultiDrag;

        handleDropOnArea(
          incomingItem,
          props.mapID,
          normalizedPoint,
          posDiff,
          props.areaId,
          isMultiDrag,
          hotKey.isAltActive
        );

        if (!isMultiDrag) {
          areaStateStore.store.task.push({
            actions: 'Add',
            targetAreaID: props.areaId,
            position: {
              x: itemX,
              y: itemY,
            },

            options: {
              element: areaRef.current,
              others: normalizedPoint,
              contentID: incomingItem.id,
            },
          });
        }

        areaStateStore.store.state = 'Execute';
      },
      collect: monitor => ({
        hovered: monitor.isOver(),
      }),
    }),
    [hotKey.isAltActive]
  );

  useEffect(() => {
    if (props.isSelected && !hovered) {
      removeItemFromSelected(
        {
          contentID: props.areaId,
          type: 'Note',
          parentID: props.areaId,
          parentType: 'AREA',
        },
        props.mapID
      );
      return;
    }

    if (hovered) {
      addItemToSelected(
        {
          contentID: props.areaId,
          type: 'Note',
          parentID: props.areaId,

          parentType: 'AREA',
        },
        props.mapID
      );
    }
  }, [hovered]);

  return (
    <div
      ref={e => {
        if (hotKey.isAltActive || hotKey.isShiftActive) {
          drag(e);
          preview(getEmptyImage(), { captureDraggingState: true });
        }
        drop(e);
        areaRef.current = e;
      }}
      style={{
        zIndex: '-10',
        position: 'absolute',
        transition: 'width 0.3s ease, height 0.5s ease',
        top: `${props.position.y}px`,
        left: `${props.position.x}px`,
        opacity: isDragging ? '50%' : '100%',
      }}
      data-drag={props.areaId}
      id={props.areaId}
      data-index={props.areaIndex}
    >
      <TitleAndStamps {...props} ID={props.areaId} mapID={props.mapID} />
      <Border
        isSelected={props.isSelected}
        style={props.style}
        type={'Area'}
        parentID={props.areaId}
        readOnly={false}
      >
        <AreaResize
          {...props}
          isSelected={props.isSelected}
          mapId={props.mapID}
          areaRef={areaRef.current}
        >
          <div
            className={'selectable'}
            id={
              hotKey.isShiftActive || hotKey.isAltActive
                ? ''
                : 'selecto-selectable'
            }
            onClick={e => {
              if (canSelect === false) {
                // prevent area from being selected on dragend
                setCanSelect(true);
                return;
              }
              e.stopPropagation();
              e.preventDefault();

              if (
                !e.metaKey &&
                props.selectoRef?.current &&
                (props.selectoRef.current as Selecto).getSelectedTargets()
                  .length >= 1
              ) {
                return;
              }

              onSelect(
                e,
                props.isSelected,
                {
                  contentID: props.areaId,
                  type: 'Area',
                  parentID: props.areaId,
                  parentType: 'AREA',
                },
                props.selectoRef
              );
            }}
            data-areabox={props.areaId}
            onDoubleClick={e => {
              handleCreateItemInArea(
                e,
                props.mapID,
                props.areaId,
                new Matrix(canvaState.canvas.CanvasTransform)
              );
            }}
            style={{
              width: '100%',
              height: 'inherit',
              color: `${props.style?.textColor}`,
              backgroundColor: `${props.style?.fillColor}`,
              opacity: '0.2',
            }}
          ></div>
        </AreaResize>
      </Border>
    </div>
  );
}

function shouldAreaRender(prevProps: AreaProps, nextProps: AreaProps) {
  if (prevProps.isSelected !== nextProps.isSelected) {
    return false;
  }

  if (prevProps.lastInteraction !== nextProps.lastInteraction) {
    return false;
  }

  return true;
}

export const AreaMemo = memo(Area, shouldAreaRender);
