import { Arrow } from 'components/icon/arrow';
import { useRef, useState } from 'react';
import { addConnectionLabel } from 'utils/mapStoreFN/mapStoreFN_connection';
import { ConnectionData } from 'utils/stores/types';
import { styled } from '@stitches/react';
import { useParams } from 'react-router-dom';
import { RenderSelectedContent } from 'CoreComponents/Renderers/RenderSelectedContent';
import { renderNoteVersion } from 'CoreComponents/Item/noteHelpers';
import { getTextWidth } from 'components/textSearchModal/MapTextSearchLabel';

const AddLabel = styled('div', {
  width: 'fit-content',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '0px 4px',
  borderRadius: '0.2em',
  scale: 'inherit',

  color: '#FCE9D7',
  backgroundColor: 'rgba(255, 255, 255, 0.192)',
  backdropFilter: 'blur(10px)',

  variants: {
    hasLabel: {
      true: {
        display: 'none',
      },
    },
    hovering: {
      false: {
        display: 'none',
      },
    },
  },

  ['& p']: {
    fontSize: '8px',
    scale: 'inherit',
    fontWeight: 'bolder',
    textTransform: 'capitalize',
  },
});

export function Connection({
  from,
  to,
  connectionId,
  type,
  isDouble,
  index,
  label,
  lastInteraction,
  useSelectionV2,
}: ConnectionData & { index: number }) {
  const { mapId } = useParams<{ mapId: string }>();
  const startCord = from.position;
  const endCord = to.position;

  const getWidth = Math.sqrt(
    (startCord.x - endCord.x) * (startCord.x - endCord.x) +
      (startCord.y - endCord.y) * (startCord.y - endCord.y)
  );

  const divRef = useRef(null);

  const [hover, setHover] = useState(false);
  const midPointX = (startCord.x + endCord.x) / 2;
  const midPointY = (startCord.y + endCord.y) / 2;

  const slope = Math.atan2(startCord.y - endCord.y, startCord.x - endCord.x);
  const SlopeDeg = (slope * 180) / Math.PI;

  const isLeft = from.position.x < to.position.x;

  return (
    <>
      <div
        ref={divRef}
        id={connectionId}
        data-index={index}
        key={connectionId}
        className="outline-none"
        style={{
          borderTopWidth: '1.5px',
          borderStyle: type === 'arrow' ? 'solid' : 'dashed',
          borderColor: hover ? 'red' : '#FCE9D7',
          zIndex: '-5',
          width: `${getWidth}px`,
          display: 'flex',
          position: 'absolute',
          height: '4px',
          top: midPointY,
          left: midPointX - getWidth / 2,
          transform: `rotate(${SlopeDeg}deg) `,
          justifyItems: 'center',
          alignItems: 'center',
          outline: 'none',
        }}
        onMouseEnter={e => {
          setHover(true);
        }}
        onMouseLeave={e => {
          setHover(false);
        }}
        onDoubleClick={e => {
          e.stopPropagation();
          e.preventDefault();

          if (!label) {
            addConnectionLabel(connectionId, mapId);
            setHover(false);
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            // minWidth: '100px',
            justifyContent: 'center',
            marginBottom: '5px',
            alignItems: 'center',
            transform: `rotate(${isLeft ? 180 : 0}deg) `,
            height: 'max-content',
            // scale: !hover && getWidth < 150 ? '0.4' : '1',
          }}
        >
          <AddLabel
            hasLabel={!!label}
            hovering={hover}
            onClick={() => {
              addConnectionLabel(connectionId, mapId);
            }}
          >
            {getWidth > 70 ? <p>add label</p> : <p>...</p>}
          </AddLabel>
          {/* getWidth <= getTextWidth(label?.content?.textContent, {}) + 10 && */}
          {getWidth > getTextWidth(label?.content?.textContent, {}) + 40 &&
            label &&
            renderNoteVersion(
              {
                item: label,
                parentID: connectionId,
                lastInteraction: label.lastInteraction,

                selectoRef: {
                  current: null,
                },
                isContainedInArea: 'none',
                totalItemsInStack: 1,
                itemIndexPosition: 0,
                isTitleNote: false,
                insertModeActive: false,
                parentPostion: { x: midPointX - getWidth / 2, y: midPointY },

                useSelectionV2: useSelectionV2,

                parentLastInteraction: lastInteraction,
                isSelected: false,
                parentType: 'CONNECTION',
              },

              mapId
            )}
        </div>
        {type === 'arrow' && (
          <Arrow
            style={{
              zIndex: '10',
              position: 'fixed',
              left: '-4px',
              top: '-4px',
              color: 'white',
              width: '12px',
              borderRadius: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              justifySelf: 'center',
              alignSelf: 'center',

              transform: `rotate(-90deg)`,
            }}
          />
        )}
        {isDouble && (
          <Arrow
            style={{
              zIndex: '10',
              position: 'fixed',
              right: ' -4px',
              top: '-4px',
              color: '#fff',
              width: '12px',
              borderRadius: '100px',

              transform: `rotate(90deg)`,
            }}
          />
        )}
      </div>
    </>
  );
}
