import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import {
  getScaleFromMatrix,
  getNormalizedCoordinates,
  getTranslateFromMatrix,
} from 'utils/helpers/Canvas/clamp-zoom';
import { v4 as uuidv4 } from 'uuid';
import {
  createNewNoteAndNewStack,
  currentMapStateStore,
  currentMapContentStore,
  getIndex,
  handleMultiDragItems,
} from 'utils/stores/mapStore';
import { AreaData, NoteItemData, Style } from 'utils/stores/types';
import {
  createNewStackOrDuplicate,
  getStack,
  getParentIdFromItemID,
  updateStackPosition,
} from './mapStoreFN_stacks';
import { DraggedItem } from 'utils/hooks/useDropOnCanvas';
import { createItemId } from './mapStoreFN_items';
import { removeDeletedItemConnection } from './mapStoreFN_connection';
import { creatingAreaMode } from 'utils/stores/mapMode/modesStore';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import { areaStateStore } from 'utils/stores/components/areaStore';
import { Note } from 'CoreComponents/Item/noteUtils';
import { Area } from 'CoreComponents/Areas/areaUtils';

//...........setters..........

export const addArea = (
  isAreaAndStack: boolean,
  x: number,
  y: number,

  mapId: string,

  width?: number,
  height?: number
) => {
  const zoomLevel = getScaleFromMatrix(
    new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
  );

  const size = !isAreaAndStack
    ? {
        width: (width && width / zoomLevel) || 400,
        height: (height && height / zoomLevel) || 400,
      }
    : { width: 400, height: 100 };

  const style = isAreaAndStack
    ? {}
    : ({
        border: {
          thickness: '_1',
          color: 'white',
        },
      } as Style);
  const newArea = new Area(
    {
      x,
      y,
    },
    size,
    style
  );

  createNewArea(mapId, newArea);
};

export function createNewArea(mapID: string, newArea: AreaData): void {
  // check if area was created with a
  // custom width  and look into the cmcs.selected
  // to get encompassing stacksIDs

  if (newArea.encompassingStacks.length) {
    currentMapContentStore[mapID].value.areas.push(newArea);
    return;
  }

  const selected = getSelectedIDs();
  const stackIDs: Set<string> = new Set();
  if (selected.length > 0) {
    // get and add stacksID to new area encompassing stacks

    selected.forEach(itemID => {
      const stackID = getParentIdFromItemID(itemID);
      stackID && stackIDs.add(stackID);
    });

    newArea.encompassingStacks = Array.from(stackIDs);
  }
  if (stackIDs.size > 0) {
    stackIDs.forEach(ID => {
      updateContainedInAreas(ID, newArea.areaId, mapID);
    });
  }
  const newAreaArray = currentMapContentStore[mapID].value.areas.push(newArea);

  return;
}

export function updatePosForStacksInArea(
  areaID: string,
  stackIDs: string[],
  offSetPosition: {
    x: number;
    y: number;
  },
  mapID: string
) {
  const canvasTransform = currentMapStateStore[mapID].canvas?.CanvasTransform;
  const zoomLevel = getScaleFromMatrix(new Matrix(canvasTransform));
  stackIDs.forEach(stack => {
    updateStackPosition(stack, offSetPosition, mapID, zoomLevel, true, areaID);
  });
}

export function updateAreaPosition(
  AreaId: string,
  newPosition: { x: number; y: number },
  mapId: string,
  xOffSet: number,
  yOffset: number,
  zoomLevel: number
) {
  const area = getArea(AreaId, mapId);
  if (!area) return;

  area.position = {
    x: area.position.x + xOffSet / zoomLevel,
    y: area.position.y + yOffset / zoomLevel,
  };

  if (area.titleNote) {
    area.titleNote.style = { ...area.titleNote.style };
  }

  if (area.encompassingStacks.length > 0) {
    updatePosForStacksInArea(
      AreaId,
      area.encompassingStacks,
      {
        x: xOffSet,
        y: yOffset,
      },
      mapId
    );
  }

  // return updateLastInteraction(AreaId, mapId);
  return;
}

export const updateAreaSize = (
  size: { width: number | null; height: number | null },
  areaId: string,
  mapId: string,
  useOffset: boolean = false,
  useScale: boolean = false
) => {
  const areaData = getArea(areaId, mapId);
  if (!areaData) return;

  if (!useOffset && !useScale) {
    areaData.size = {
      ...size,
    };
  }

  if (useScale) {
    const canvas = currentMapStateStore[mapId].canvas;

    const zoomLevel = getScaleFromMatrix(new Matrix(canvas.CanvasTransform));
    const scaleWidth = size.width ? size.width / zoomLevel : 0;
    const scaleHeight = size.height ? size.height / zoomLevel : 0;

    size.height = size.height + scaleHeight;
    size.width = size.height + scaleWidth;
  }

  if (useOffset) {
    areaData.size.width += size.width;
    areaData.size.height += size.height;
  }
};

type UpdateAreaStacksAction = 'REMOVE' | 'ADD';

export const updateEncompassingStacks = (
  action: UpdateAreaStacksAction,
  mapId: string,
  stackID: string[],
  areaID: string,
  numberOfItem: number,
  areaDataObject?: AreaData,
  fromAreaID?: string
) => {
  const area = areaDataObject || getArea(areaID, mapId);

  if (!area) return;

  switch (action) {
    case 'REMOVE':
      if (numberOfItem > 1) return;

      area.encompassingStacks = [
        ...area.encompassingStacks.filter(id => id !== stackID[0]),
      ];

      break;
    case 'ADD':
      let newStackSet = new Set([...area.encompassingStacks, ...stackID]);
      area.encompassingStacks = [...Array.from(newStackSet)];

      break;
    default:
      break;
  }
};

//..................getters.............

export function getAreaFromValtio(
  areaID: string,
  mapID: string
): AreaData | undefined {
  if (!mapID) {
    return undefined;
  }
  const areaData = currentMapContentStore[mapID].value.areas.find(
    area => area.areaId === areaID
  );
  return areaData;
}

export function getArea(areaID: string, mapID: string): AreaData | undefined {
  const areaData = getAreaFromValtio(areaID, mapID);
  if (areaData) {
    areaData.lastInteraction = new Date();
  }
  return areaData;
}

// ...............modifiers.............

export function removeAreaFromMap(areaID: string, mapID: string) {
  let areaIndex = getIndex(areaID, mapID);

  if (areaIndex !== -1) {
    // set containedInArea value to none before
    // deleting area.
    currentMapContentStore[mapID].value.areas[
      areaIndex
    ].encompassingStacks.forEach(stackID => {
      updateContainedInAreas(stackID, 'none', mapID);
    });
    currentMapContentStore[mapID].value.areas.splice(areaIndex, 1);
    // updateLastInteraction(areaID, mapID);
  }
}

export const updateContainedInAreas = (
  stackId: string,
  areaId: string,
  mapId: string
) => {
  // TODO   refactor this to work with stack.containedInAreas after removing isContainedInArea

  const stack = getStack(stackId, mapId);

  if (!stack) return;

  stack.isContainedInArea = areaId;
  // updateLastInteraction(stackId, mapId);
};
// .................Effects..............
export function cursorOnArea(
  mousePostion: { x: number; y: number },
  mapID: string
): string | undefined {
  const { x: stackx, y: stacky } = mousePostion;

  for (const areaRef of currentMapContentStore[mapID].value.areas) {
    const {
      position: { x, y },
      size: { width, height },
      areaId,
      encompassingStacks,
    } = areaRef;
    const isInArea =
      stackx >= x && stacky >= y && stackx <= x + width && stacky <= y + height;
    if (isInArea) {
      // containingArea = { width, height, x, y, areaId };

      return areaId;
    }
  }
}

export function handleDropOnArea(
  incomingItem: DraggedItem,
  mapId: string,
  position: { x: number; y: number },
  monitioPositionDif: { x: number; y: number },
  areaID: string,
  isMultiDrag: boolean,
  createDuplicate: boolean
) {
  const droppedItem = incomingItem;

  const areaData = getArea(areaID, mapId);
  if (!areaData) return;
  if (!isMultiDrag) {
    const newPosition = {
      x: position.x,
      y: position.y,
    };
    const stackID = 'stack_' + uuidv4();
    createNewStackOrDuplicate(
      newPosition,
      [droppedItem.item],
      mapId,
      areaID,
      undefined,
      false,
      createDuplicate,

      stackID
    );
    updateEncompassingStacks(
      'ADD',
      mapId,
      [stackID],
      areaID,
      incomingItem.totalItemsInStack
    );
    return;
  }

  const zoomLevel = getScaleFromMatrix(
    new Matrix(currentMapStateStore[mapId].canvas.CanvasTransform)
  );

  areaStateStore.store.state = 'pending';
  areaStateStore.store.area = areaData;
  handleMultiDragItems(
    monitioPositionDif,
    zoomLevel,
    mapId,
    createDuplicate,
    null,
    areaID
  );
  return;
}

export function handleCreateItemInArea(
  e: React.MouseEvent,
  mapID: string,
  areaID: string,
  canvasTransform: DOMMatrix
): void {
  if (e.target === e.currentTarget) {
    const canvas = currentMapStateStore[mapID].canvas?.CanvasTransform;

    const zoomLevel = getScaleFromMatrix(new Matrix(canvas));
    const noteMouseXOffset = 8 * zoomLevel;
    const noteMouseYoffset = 12 * zoomLevel;
    const stackID = 'stack_' + uuidv4();
    const pos = getNormalizedCoordinates(
      canvas,
      e.clientX - noteMouseXOffset,
      e.clientY - noteMouseYoffset
    );

    updateEncompassingStacks('ADD', mapID, [stackID], areaID, 1);
    createNewNoteAndNewStack(
      { x: pos.x, y: pos.y },
      null,
      mapID,
      areaID,
      false,
      stackID
    );
  }
}

export const duplicateArea = (
  area: AreaData,
  mapId: string,
  position: { x: number; y: number },
  positionOffset: { x: number; y: number },
  newAreaID?: string
) => {
  const { x, y } = positionOffset;

  // A new id for the duplicated area which would be created
  const newAreaId = newAreaID || 'area_' + uuidv4();
  let newEncompassingStacks: string[] = [];

  // Checking if the former area has encompassing stacks in it
  // to also duplicate the stack in it too
  if (area.encompassingStacks.length) {
    area.encompassingStacks.forEach(stack => {
      // get the stack data
      const stackData = currentMapContentStore[mapId].value.stacks.find(
        item => item.stackId === stack
      );

      // Checking if stackData exists and if it has items in it
      if (stackData && stackData?.items.length) {
        // Give the new stack items new ids
        const newStackItems = stackData.items.map(item => {
          return { ...item, itemId: createItemId() };
        });

        // The creating a new stack and pushing the stackId to
        // the encompassingStacks for the new duplicated area

        const newStackID = 'stack_' + uuidv4();
        createNewStackOrDuplicate(
          { x: stackData.position.x + x, y: stackData.position.y + y },
          newStackItems,
          mapId,
          newAreaId,
          false,
          true, // for the return value
          true, // for duplicating
          newStackID
        );

        newEncompassingStacks.push(newStackID);
      }
    });
  }

  // Generating a  new area data with the newAreaId and retaining some style properties of the previous area

  const newArea = new Area(position, { ...area.size }, { ...area.style });

  // Function to create a new area (The duplicate)
  createNewArea(mapId, newArea);
};

export function createStackInArea(
  areaPosition: { x: number; y: number },
  areaID: string,
  mapId: string
) {
  const mapState = currentMapStateStore[mapId];
  const areaNode = document
    .querySelector(`div[data-areabox="${areaID}"]`)
    ?.getBoundingClientRect();
  if (!areaNode) return;

  const { x, y } = getTranslateFromMatrix(
    new Matrix(mapState.canvas.CanvasTransform)
  );
  const zoomLevel = getScaleFromMatrix(
    new Matrix(mapState.canvas.CanvasTransform)
  );
  const pos = {
    x: (areaNode.left - x) / zoomLevel - 8,
    y: (areaNode.top - y) / zoomLevel - 16,
  };

  //TODO  update this to account the height of the titleNote
  // when moving to the new selection store implementation.

  const newPosition = { x: pos.x + 80, y: pos.y + 80 };

  const stackID = 'stack_' + uuidv4();
  updateEncompassingStacks('ADD', mapId, [stackID], areaID, 1);
  createNewNoteAndNewStack(newPosition, null, mapId, areaID, false, stackID);

  creatingAreaMode.reset();
}

//   ............ titleNote and stamps

export function deleteAreaTitleNote(tileNoteID: string, mapId: string) {
  const areaID = getParentIdFromItemID(tileNoteID);
  const area = getArea(areaID, mapId);

  if (!area) {
    return;
  }

  area?.titleNote?.connections?.length > 0 &&
    removeDeletedItemConnection(
      tileNoteID,
      area?.titleNote?.connections,
      mapId
    );
  area.titleNote = null;
}

export function addAreaTitleNote(areaID: string, mapId: string) {
  const area = getArea(areaID, mapId);
  const titleNote: NoteItemData = new Note(
    true,
    null,
    'Note',
    null,
    {},
    'AREA',
    'titleNote'
  ) as NoteItemData;

  area.titleNote = titleNote;
}
