import { useFlag } from '@unleash/proxy-client-react';
import { Connection } from 'CoreComponents/Connections/connection';
import { useParams } from 'react-router-dom';
import { currentMapContentStore } from 'utils/stores/mapStore';
import { NoteItemData } from 'utils/stores/types';
import { useSnapshot } from 'valtio';

export default function RenderConnections() {
  const mapId = useParams().mapId!;
  const useSelectionV2 = useFlag('mapmap_selectionv2');
  const connections = useSnapshot(currentMapContentStore[mapId]).value
    .connections;
  return (connections || []).map((connection, index) => (
    <Connection
      key={connection.connectionId}
      {...connection}
      index={index}
      label={connection.label as NoteItemData}
      useSelectionV2={useSelectionV2}
    />
  ));
}
