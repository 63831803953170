/**
 ** this contains types used by slateEditor
 * Elements types
 * Text types
 */

import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';

export type SlateActionsTypes = 'Link' | 'Highlight' | 'Home' | 'Close';

export type FormatedText = {
  text: string;
  isBold?: boolean;
  isItalic?: boolean;
  isStrikeThrough?: boolean;
  isUnderlined?: boolean;
  highlight?: boolean;
};

export type CustomText = FormatedText;

type HOTKEYS = {
  [key: string]: keyof FormatedText;
};

export const cFORMATHOTKEYS: HOTKEYS = {
  'meta+b': 'isBold',
  'meta+i': 'isItalic',
  'meta+u': 'isUnderlined',
  'meta+g': 'highlight',
} as const;

export const cSLATE_ACTION_HOTKEYS = {
  'meta+v': 'Paste',
  'meta+/': 'SplitNote',
  'meta+Enter': 'NewNote',
  'meta+Shift+Enter': 'NewNote',
  'Shift+Enter': 'ExitEditMode',
} as const;

export type ActionHotkeys = typeof cSLATE_ACTION_HOTKEYS;
export type ActionHotkeyValues = ActionHotkeys[keyof ActionHotkeys];

export type Mark = keyof FormatedText;

export interface ParagraphElement {
  type: 'Paragraph';
  children: CustomText[];
}

export interface DefaultElement {
  type: 'Default';
  children: CustomText[];
}

export interface ImageElement {
  type: 'Image';
  url: string;
  alt: string;
  children: CustomText[];
}

export interface LinkElement {
  type: 'Link';
  children: CustomText[];
  url: string;
}

export type CustomElement =
  | ParagraphElement
  | LinkElement
  | ImageElement
  | DefaultElement;
export type CustomEditor = BaseEditor & HistoryEditor & ReactEditor;
