import { currentMapContentStore } from 'utils/stores/mapStore';
import {
  MapPreviewContent,
  PreviewData,
  WebPreviewContent,
} from 'utils/stores/types';
import { v4 as uuidv4 } from 'uuid';

export const createPreview = (
  position: { x: number; y: number },
  content: WebPreviewContent | MapPreviewContent,
  mapId: string,
  size?: {
    width: number;
    height: number;
  }
) => {
  if (!size) size = { height: 321, width: 514 };

  const newPreview: PreviewData = {
    previewId: 'preview_' + uuidv4(),
    type: content.type,
    name: 'PREVIEW',
    content,
    connections: [],
    lastInteraction: new Date(),
    delete: false,
    position,
    size,
    style: {
      border: {
        thickness: '_0',
      },
    },
    scrollPosition: {
      x: 0,
      y: 0,
    },
  };

  if (!currentMapContentStore[mapId].value.previews) {
    currentMapContentStore[mapId].value.previews = [newPreview];
  } else {
    currentMapContentStore[mapId].value.previews.push(newPreview);
  }
  console.log(content.link);

  // updateLastInteraction(newPreview.previewId, mapId);
};

export const getPreview = (previewId: string, mapId?: string) => {
  let preview: PreviewData | null = null;

  if (!mapId) return;

  const getPreviewIndex = document
    .getElementById(previewId)
    ?.getAttribute('data-preview-index');

  if (getPreviewIndex) {
    preview =
      currentMapContentStore[mapId].value.previews[Number(getPreviewIndex)];
  }

  return preview;
};

export const updatePreviewPosition = (
  previewId: string,
  newPosition: { x?: number; y?: number },
  mapId: string
) => {
  console.log(newPosition);
  const preview = getPreview(previewId, mapId);
  if (!preview) return;

  preview.position = {
    x: newPosition.x || preview.position.x,
    y: newPosition.y || preview.position.y,
  };

  // updateLastInteraction(previewId, mapId);
};

export function removePreviewFromMap(previewId: string, mapID: string) {
  let previewIndex = currentMapContentStore[mapID].value.previews.findIndex(
    preview => preview.previewId === previewId
  );

  if (previewIndex !== -1) {
    currentMapContentStore[mapID].value.previews[previewIndex];
    currentMapContentStore[mapID].value.previews.splice(previewIndex, 1);
  }
}
