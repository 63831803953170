import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import {
  AreaData,
  ConnectionData,
  MapData,
  PreviewData,
  StackData,
} from 'utils/stores/types';
import { v4 as uuidv4 } from 'uuid';

export namespace MapUtils {
  export class Map implements MapData {
    mapId: string;
    name: string;
    lastInteraction: Date;
    lastSync: Date;

    canvas: {
      CanvasTransform: string;
    };
    stacks: StackData[];
    areas: AreaData[];
    previews: PreviewData[];
    connections: ConnectionData[];

    constructor(mapID: string, name: string) {
      this.areas = [];
      this.stacks = [];
      this.connections = [];
      this.previews = [];
      this.lastInteraction = new Date();
      this.lastSync = new Date();
      this.name = name;
      mapID = mapID || uuidv4();

      this.canvas = {
        CanvasTransform: new Matrix().toString(),
      };
    }
  }
}
