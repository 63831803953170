import { useDrop } from 'react-dnd';
import { BaseDraggable, ItemData, PreviewData } from 'utils/stores/types';
import {
  getNormalizedCoordinates,
  getScaleFromMatrix,
} from '../helpers/Canvas/clamp-zoom';

import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import {
  currentMapStateStore,
  handleMultiDragItems,
  setSelected,
} from 'utils/stores/mapStore';

import {
  duplicateArea,
  getArea,
  updateAreaPosition,
} from 'utils/mapStoreFN/mapStoreFN_areas';
import { updatePreviewPosition } from 'utils/mapStoreFN/mapStoreFN_previews';
import { createNewStackOrDuplicate } from 'utils/mapStoreFN/mapStoreFN_stacks';
import { areaStateStore } from 'utils/stores/components/areaStore';
import { gestureStore, setDragging } from 'utils/stores/gestureStore';
import { updateMapMode } from 'utils/stores/mapmodesStore';

export interface DraggedItem extends BaseDraggable {
  item: ItemData;
  // height: number;
  parentID: string;
  isContainedInArea: string;
  createDuplicate: boolean;
  totalItemsInStack: number;
  itemIndexPosition: number;
  height: number;
  width: number;
  itemRef: HTMLDivElement | HTMLElement;
  initialOffset: {
    x: number;
    y: number;
  };
}
export interface DraggedArea extends BaseDraggable {
  areaId: string;
  backgroundColor: string | undefined;
  defaultTextColor: string;
  width: number;
  height: number;
  fillColor?: string;
  createDuplicate: boolean;
  encompassingStacks: string[];
  position: {
    x: number;
    y: number;
  };
}
export interface DraggedPreview extends BaseDraggable {
  preview: PreviewData;
}

const useDropOnCanvas = (mapId: string, gestureStarted: boolean) => {
  const currentMapState = currentMapStateStore[mapId];
  const canvasTransform = currentMapState.canvas?.CanvasTransform;

  return useDrop(
    () => ({
      accept: ['Item', 'Stack', 'Area', 'Preview'],
      // accept: ['Item', 'Stack', 'Area', 'Preview'],

      drop: (
        incomingItem: DraggedArea & DraggedItem & DraggedPreview,
        monitor
      ) => {
        if (!monitor.isOver({ shallow: true })) return;

        const posDiff = monitor.getDifferenceFromInitialOffset();
        const DraggedItemPosition = monitor.getInitialSourceClientOffset();
        const { x, y } = getNormalizedCoordinates(
          canvasTransform,
          DraggedItemPosition.x,
          DraggedItemPosition.y
        );

        if (!posDiff) return;

        const zoomLevel = getScaleFromMatrix(new Matrix(canvasTransform));

        // multiDrag And Duplication
        if (
          gestureStore.Dragging.isDragging &&
          gestureStore.Dragging.isMultiDrag
        ) {
          // Handle multiple item/area drag and drop
          handleMultiDragItems(
            { x: posDiff.x, y: posDiff.y },
            zoomLevel,
            mapId,
            (incomingItem as DraggedItem | DraggedArea).createDuplicate,
            incomingItem,
            undefined,
            {
              x,
              y,
            }
            // monitor
          );
          updateMapMode('droppingMultiSelected', true);
          setDragging(false, false);
          return;
        }

        // single Drag And Duplicating
        let newPosition = undefined;
        switch ((incomingItem as DraggedItem | DraggedArea).type) {
          case 'Area':
            const droppedArea = incomingItem as DraggedArea;

            // If alt + drag is triggered
            if ((incomingItem as DraggedArea).createDuplicate) {
              // Getting the real areaData that we want to duplicate
              const areaData = getArea(droppedArea.areaId, mapId);

              // ! This might be changed as there might be a better approach
              const duplicatePosition = {
                x: areaData.position.x + posDiff.x / zoomLevel,
                y: areaData.position.y + posDiff.y / zoomLevel,
              };

              if (areaData) {
                duplicateArea(areaData, mapId, duplicatePosition, {
                  x: posDiff.x / zoomLevel,
                  y: posDiff.y / zoomLevel,
                });
              }

              return;
            }

            updateAreaPosition(
              droppedArea.areaId,
              { x, y },
              mapId,
              posDiff.x,
              posDiff.y,
              zoomLevel
            );
            break;
          // case 'Image':
          case 'Note':
            const droppedItem = incomingItem as DraggedItem;
            if (droppedItem.isContainedInArea !== 'none') {
              areaStateStore.store.task.push({
                targetAreaID: droppedItem.isContainedInArea,
                actions: 'Drag',
              });
              areaStateStore.store.state = 'Execute';
            }

            const createdStack = createNewStackOrDuplicate(
              {
                x: x + posDiff.x / zoomLevel,
                y: y + posDiff.y / zoomLevel,
              },
              [incomingItem.item],
              mapId,
              null,
              null,
              incomingItem.createDuplicate,
              incomingItem.createDuplicate
            );

            if (createdStack) {
              // this is for a singleDuplicate function
              setSelected(
                {
                  contentID: createdStack.items[0].itemId,
                  parentType: 'STACK',
                  type: 'Note',
                  parentID: createdStack.stackId,
                },
                mapId
              );
            }

            break;
          case 'Preview':
            const droppedPreview = incomingItem as DraggedPreview;
            newPosition = {
              x: x + posDiff.x / zoomLevel,
              y: y + posDiff.y / zoomLevel,
            };

            updatePreviewPosition(
              droppedPreview.preview.previewId,
              newPosition,
              mapId
            );
            break;
          default:
            new Error('dropped type not defined');
        }
        setDragging(false, false);
      },

      collect: monitor => ({
        exitViewportDrag: monitor.canDrop() || monitor.didDrop(),
      }),
      revert: false,
    }),
    [gestureStarted, canvasTransform]
  );
};

export default useDropOnCanvas;
