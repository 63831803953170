import { Descendant } from 'slate';
import { createItemId } from 'utils/mapStoreFN/mapStoreFN_items';
import { ItemData, ItemType, ParentType, Style } from 'utils/stores/types';

import { themeStore } from 'utils/stores/themeStore';

export const calculateDropZone = (
  dropTargetRect: DOMRect,
  dropPosition: { y: number }
) => {
  const dropTargetTop = dropTargetRect.top;
  const dropTargetHeight = dropTargetRect.height;
  const midPoint = dropTargetTop + dropTargetHeight / 2;

  return dropPosition.y < midPoint ? 'above' : 'below';
};

export const applyItemFontSize = (item: ItemData) => {
  let fontSize = item?.style?.fontSize || Number(themeStore.fontSize);

  if (item?.style?.isTask) {
    // 20% of the normal text
    fontSize += fontSize * 0.2;
  }

  return fontSize;
};

export const applyCheckMarkSize = (item: ItemData) => {
  let fontSize = item?.style?.fontSize || Number(themeStore.fontSize);

  // Cross check this default fontSize
  const defaultFontSize = 16;
  const baseFontSize = fontSize || defaultFontSize;
  const sizeIncrease = Math.floor((baseFontSize - defaultFontSize) / 10) * 2;
  const checkboxSize = Math.max(16, 16 + sizeIncrease);
  const checkSize = Math.max(10, 10 + sizeIncrease);

  return {
    checkboxSize,
    checkSize,
  };
};

/**
 *
 * @param createAt : Date value;
 *
 *
 * @description
 * this takes in the lastInteraction value and return true or
 * false if the note is a new note
 *
 * @return boolean
 */

export function toggleEditMode(
  textContent: string | null,
  newNote: boolean,
  type: ItemData['type'],
  editmode: 'yes' | 'no'
) {
  if (type === 'Image') {
    return false;
  }
  if (editmode === 'yes') {
    return true;
  }
  if (!newNote) {
    return;
  }
  return newNote && textContent === null;
}

/**
 * Represents a Note item with various properties and methods.
 *
 * @class Note
 *
 * @property {string} itemId - The unique identifier for the note.
 * @property {'Note' | 'Image'} type - The type of the note, either 'Note' or 'Image'.
 * @property {string[]} connections - An array of connection identifiers associated with the note.
 * @property {{ x: number; y: number }} position - The position of the note on the canvas.
 * @property {Date} lastInteraction - The timestamp of the last interaction with the note.
 * @property {Style} style - The style object defining the appearance of the note.
 * @property {boolean} delete - A flag indicating whether the note is marked for deletion.
 * @property {{ slateContent: Descendant[] | null; textContent?: string | null } | { src: string }} content - The content of the note, which can be either text or an image source.
 *
 * @constructor
 * @param {ParentType} parenType - The type of the parent container, such as "stack", "area", or "connection".
 * @param {ItemType} itemType - The type of the item, such as "Default", "stamp", "label", or "titleNote".
 * @param {'Image' | 'Note'} [type='Note'] - The type of the note, either 'Image' or 'Note'.
 * @param {Style} [style={}] - The style object defining the appearance of the note.
 * @param {string} [noteID] - The unique identifier for the note. If not provided, a new ID will be generated.
 * @param {Descendant[]} [slateContent=null] - The slate content of the note. Provide a value for this if you want the Note to be created with text content, e.g., for paste or noteSplit operations.
 *
 * @returns {Note} An instance of a Note object.
 */
export class Note {
  itemId: string;
  type: 'Note' | 'Image';
  connections: string[];
  position: { x: number; y: number };
  lastInteraction: Date;
  style: Style;
  delete: boolean;
  newNote: boolean;

  content:
    | {
        slateContent: Descendant[] | null;
        textContent?: string | null;
      }
    | {
        src: string;
      };

  /**
   *
   * * @param newNote : pass true if you want the created note to toggle to editmode on render
   * @param parenType : "stack" | "area" | "connection"
   * @param itemType :"Default" | "stamp" | "label" | "titleNote"
   * @param type : Image | Note
   * @param style : Style object
   * @param noteID : note ID
   * @param slateContent note slate content : Descendant[] - provide a value for this if you
   
   * want the Note to be created a with a text content. e.g paste or noteSplit
   *
   *
   * @returns an instance of a ItemData object
   */

  constructor(
    newNote = false,
    slateContent: Descendant[] = null,
    type: 'Image' | 'Note' = 'Note',
    noteID?: string,

    style: Style = {},
    parenType: ParentType = 'STACK',
    itemType: ItemType = 'Default'
  ) {
    this.itemId = noteID || createItemId(parenType, itemType);
    this.connections = [];
    this.type = type;
    this.content = { slateContent, textContent: null };

    this.lastInteraction = new Date();
    this.position = { x: 0, y: 0 };
    this.style = { ...style };
    this.delete = false;
    this.newNote = newNote;
  }
}
