import { Matrix } from 'CoreComponents/Canvas/InfiniteCanvas';
import {
  createNewNoteAndNewStack,
  currentMapStateStore,
} from 'utils/stores/mapStore';
import {
  getNormalizedCoordinates,
  getScaleFromMatrix,
} from './Canvas/clamp-zoom';
import { creatingAreaMode } from 'utils/stores/mapMode/modesStore';
import { createArea } from 'CoreComponents/Areas/areaUtils';

const handleCreateNewNote = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  m: DOMMatrix,
  mapId: string,
  areaId?: string,
  isShiftPressed?: boolean,
  selectoRef?: any
) => {
  // if target and current target are equal, means canvas element has been double clicked
  if (
    selectoRef &&
    selectoRef.current &&
    selectoRef.current?.getSelectedTargets().length > 0
  )
    return;
  if (e.target === e.currentTarget) {
    const canvas = currentMapStateStore[mapId].canvas?.CanvasTransform;
    // const pos = transformedMouseCordinates(
    //   {
    //     x: e.clientX,
    //     y: e.clientY,
    //   },
    //   mapId
    // );

    const zoomLevel = getScaleFromMatrix(
      new Matrix(currentMapStateStore[mapId].canvas?.CanvasTransform)
    );
    const noteMouseXOffset = 8 * zoomLevel;
    const noteMouseYoffset = 12 * zoomLevel;

    const stackHeadline = 28 * zoomLevel;
    const pos = getNormalizedCoordinates(
      canvas,
      e.clientX - noteMouseXOffset,
      e.clientY - noteMouseYoffset
    );

    if (isShiftPressed) {
      creatingAreaMode.activateAreaAndStack();
      createArea(true, mapId, {
        x: e.clientX - stackHeadline,
        y: e.clientY,
      });
    } else {
      createNewNoteAndNewStack({ x: pos.x, y: pos.y }, null, mapId, areaId);
    }
  }
};

export default handleCreateNewNote;
