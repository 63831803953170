import { ItemData } from './stores/types';
import { v4 as uuidv4 } from 'uuid';

export const MAX_ZOOM = 12;
export const ZOOM_SPEED_FACTOR = 2;
export const MIN_ZOOM_FACTOR = 3;

export const EXPAND_THRESHOLD = 100; // Distance from edge to start expanding

export const EXPAND_AMOUNT = 320; // How much to expand when needed

export const mapSuggestedIcons = [
  {
    id: 1,
    unified: '1F5FA',
    size: 32,
    isSelected: false,
    icon: '🗺️',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 2,
    unified: '1f44d',
    size: 32,
    isSelected: false,
    icon: '🍔',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 3,
    unified: '2764-fe0f',
    size: 32,
    isSelected: false,
    icon: '👨‍🍳',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 4,
    unified: '1f603',
    size: 32,
    isSelected: false,
    icon: '🚇',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 5,
    unified: '',
    size: 32,
    isSelected: false,
    icon: '🌿',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 6,
    unified: '',
    size: 32,
    isSelected: false,
    icon: '🌞',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 7,
    unified: '',
    size: 32,
    isSelected: false,
    icon: '💧',
    iconType: 'emoji',
    color: '',
  },
];

export const folderSuggestedIcons = [
  {
    id: 1,
    unified: '1f4c2',
    size: 32,
    isSelected: false,
    icon: '📂',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 2,
    unified: '1f44d',
    size: 32,
    isSelected: false,
    icon: '🚢',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 3,
    unified: '2764-fe0f',
    size: 32,
    isSelected: false,
    icon: '🖥️',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 4,
    unified: '1f603',
    size: 32,
    isSelected: false,
    icon: '📲',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 5,
    unified: '',
    size: 32,
    isSelected: false,
    icon: '🌙',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 6,
    unified: '',
    size: 32,
    isSelected: false,
    icon: '✨',
    iconType: 'emoji',
    color: '',
  },
  {
    id: 7,
    unified: '',
    size: 32,
    isSelected: false,
    icon: '🔥',
    iconType: 'emoji',
    color: '',
  },
];

export const CabanaIcons: string[] = [
  'CabanaChartsGraphsPie5Duotone',
  'CabanaCommunicationMailDuotone',
  'CabanaDateTimeCalendar03Duotone',
  'CabanaMediaControlsMicrophoneDuotone',
  'CabanaMediaControlsMusicalNote01Duotone',
  'CabanaNavigationTravelPointerLeftDuotone',
  'CabanaPersonalCareShowerDuotone',
  'CabanaSportsFitnessMedalDuotone',
  'CabanaUserInterfaceWarningDuotone',
  'CabanaChartsGraphsCircular8Duotone',
  'CabanaDateTimeAlarmDuotone',
  'CabanaDevicesElectronicsWiFiDuotone',
  'CabanaUserInterfaceBookmarkCheckDuotone',
  'CabanaDevicesElectronicsSIMDuotone',
  'CabanaFoodDrinksCarrotDuotone',
  'CabanaFoodDrinksCup01Duotone',
  'CabanaNavigationTravelCampsiteDuotone',
  'CabanaSocialMediaBrandsDribbbleDuotone',
  'CabanaSportsFitnessShortsDuotone',
  'CabanaWeatherThunderstorm02Duotone',
  'CabanaDesignRulerDuotone',
  'CabanaFoodDrinksAvocadoDuotone',
  'CabanaDevicesElectronicsLaptopDuotone',
  'CabanaDevicesElectronicsElectricalPlugDuotone',
  'CabanaDevicesElectronicsLightBulbDuotone',
  'CabanaFoodDrinksLollipopDuotone',
  'CabanaDesignImageDuotone',
  'CabanaFoodDrinksSoupDuotone',
  'CabanaShoppingEcommerceDeliveryTruckDuotone',
  'CabanaSocialMediaBrandsAndroidDuotone',
  'CabanaDevicesElectronicsControllerDuotone',
  'CabanaMediaControlsPlayCircleDuotone',
  'CabanaFoodDrinksCupToGoDuotone',
  'CabanaMedicineHealthPillDuotone',
  'CabanaNavigationTravelLocationDuotone',
  'CabanaSocialMediaBrandsAppleDuotone',
  'CabanaUserInterfaceCheckCircleDuotone',
  'CabanaSportsFitnessStandingsDuotone',
  'CabanaArrowsRefresh',
  'CabanaDatabaseDatabase',
];

export const MicrosoftIcons: string[] = [
  'MicrosoftAirplane24Solid',
  'MicrosoftAttach24Solid',
  'MicrosoftColor24Solid',
  'MicrosoftClass24Line',
  'MicrosoftFolderOpen24Solid',
  'MicrosoftEyeShow24Solid',
  'MicrosoftFlashOn24Line',
  'MicrosoftLibrary24Solid',
  'MicrosoftLive24Solid',
  'MicrosoftMyLocation24Line',
  'MicrosoftPerson24Solid',
  'MicrosoftBookFormulaEngineering24Line',
  'MicrosoftMidi24Solid',
  'MicrosoftPhoneMobile24Solid',
  'MicrosoftText24Solid',
  'MicrosoftGlance24Solid',
  'MicrosoftShield24Solid',
  'MicrosoftSend24Solid',
  'MicrosoftSlowMode24Line',
  'MicrosoftEdit24Solid',
  'MicrosoftSurfaceHub24Solid',
  'MicrosoftReadingMode24Line',
  'MicrosoftDesktop24Line',
  'MicrosoftDeveloperBoard24Solid',
  'MicrosoftDriveMode24Solid',
  'MicrosoftCollections24Solid',
  'MicrosoftBot24Line',
  'MicrosoftComment24Solid',
  'MicrosoftCloud24Solid',
  'MicrosoftBed24Line',
  'MicrosoftFastMode24Line',
  'MicrosoftHome24Solid',
  'MicrosoftCamera24Solid',
];
