import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  QuickAccessFolderItem,
  QuickAccessMapItem,
  folderHasChildren,
  useQuickAccessData,
} from '../SharedQuickAccessComponents';
import { useNavigate } from 'react-router-dom';
import { toggleQuickAccessModal } from 'utils/stores/dashboardStore';
import GeneralSkeleton from 'components/loader/skeletons/GeneralSkeleton/GeneralSkeleton';

const maxHeightPerColumn = 300;
const minHeightPerItem = 40;
const gapBetweenItems = 8;

const maxColumns = 3;
export default function QuickAccessModalItems() {
  const navigate = useNavigate();
  const [expandedFolderGroups, setExpandedFolderGroups] = React.useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState({
    column: 0,
    row: 0,
    subRow: -1,
  });

  const containerRef = useRef(null);

  const createColumns = items => {
    const columns = Array.from({ length: maxColumns }, () => []);
    let currentColumn = 0;
    let currentHeights = Array(maxColumns).fill(0);

    items.forEach(item => {
      const itemHeight = item.height || minHeightPerItem;
      const estimatedHeight = itemHeight + gapBetweenItems;

      if (
        currentHeights[currentColumn] + estimatedHeight >
        maxHeightPerColumn
      ) {
        currentColumn = (currentColumn + 1) % maxColumns;
      }

      columns[currentColumn].push(item);
      currentHeights[currentColumn] += estimatedHeight;
    });

    // Balance items across columns if any column exceeds maxHeightPerColumn
    if (currentHeights.some(height => height > maxHeightPerColumn)) {
      const allItems = columns.flat();
      // Clear all columns
      columns.forEach(column => (column.length = 0));
      currentHeights.fill(0);

      allItems.forEach((item, index) => {
        const column = index % maxColumns;
        columns[column].push(item);
        currentHeights[column] +=
          item.height || minHeightPerItem + gapBetweenItems;
      });
    }

    return columns;
  };

  const { folders, maps, structureLoading, handleItemSelection } =
    useQuickAccessData();

  const [columns, setColumns] = useState<any[][]>([]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (
        !['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(
          e.key
        )
      )
        return;

      e.preventDefault();
      let { column, row, subRow } = selectedItemIndex;

      const currentItem = columns[column][row];
      const currentItemSubFolders = (currentItem?.subfolders || []).filter(
        folder => folder.isInQuickAccess
      );
      const currentItemMaps = (currentItem?.maps || []).filter(
        map => map.isInQuickAccess
      );
      const isCurrentItemExpanded =
        'folderId' in currentItem &&
        expandedFolderGroups.includes(currentItem.folderId);

      switch (e.key) {
        case 'ArrowUp':
          if (isCurrentItemExpanded) {
            const childCount =
              (currentItemSubFolders?.length || 0) +
              (currentItemMaps?.length || 0);
            if (subRow > 0) {
              subRow--;
            } else if (subRow === 0) {
              subRow = -1; // Move to parent folder
            } else {
              subRow = childCount - 1; // Loop to last child
            }
          } else if (row > 0) {
            row--;
            subRow = -1;
          }
          break;
        case 'ArrowDown':
          if (isCurrentItemExpanded) {
            const childCount =
              (currentItemSubFolders?.length || 0) +
              (currentItemMaps?.length || 0);
            if (subRow < childCount - 1) {
              subRow++;
            } else {
              subRow = 0; // Loop back to the first item in the expanded list
            }
          } else if (row < columns[column].length - 1) {
            row++;
            subRow = -1;
          }
          break;
        case 'ArrowLeft':
          if (!isCurrentItemExpanded) {
            column = Math.max(0, column - 1);
            row = Math.min(row, columns[column].length - 1);
            subRow = -1;
          }
          break;
        case 'ArrowRight':
          if (!isCurrentItemExpanded) {
            column = Math.min(columns.length - 1, column + 1);
            row = Math.min(row, columns[column].length - 1);
            subRow = -1;
          }
          break;
        case 'Enter':
          if ('mapId' in currentItem) {
            navigate(`/map/${currentItem.mapId}`);
            toggleQuickAccessModal();
          } else if ('folderId' in currentItem) {
            if (folderHasChildren(currentItem)) {
              if (isCurrentItemExpanded && subRow >= 0) {
                // Handle enter on child item
                const childItem = [
                  ...(currentItemSubFolders || []),
                  ...(currentItemMaps || []),
                ][subRow];
                if ('mapId' in childItem) {
                  navigate(`/map/${childItem.mapId}`);
                  toggleQuickAccessModal();
                } else {
                  navigate(`/dashboard/folder/${childItem.folderId}`);
                  toggleQuickAccessModal();
                }
              } else {
                setExpandedFolderGroups(prev =>
                  prev.includes(currentItem.folderId)
                    ? []
                    : [currentItem.folderId]
                );
                subRow = isCurrentItemExpanded ? -1 : 0;
              }
            } else {
              navigate(`/dashboard/folder/${currentItem.folderId}`);
              toggleQuickAccessModal();
            }
          }
          break;
      }

      setSelectedItemIndex({ column, row, subRow });
    },
    [selectedItemIndex, columns, navigate, expandedFolderGroups]
  );

  const isSelected = (
    columnIndex: number,
    rowIndex: number,
    subRowIndex: number = -1
  ) =>
    selectedItemIndex.column === columnIndex &&
    selectedItemIndex.row === rowIndex &&
    (subRowIndex === -1 || selectedItemIndex.subRow === subRowIndex);

  const shouldHighlightItem = (column, row) => {
    const item = columns[column][row];

    return (
      isSelected(column, row) &&
      (!('folderId' in item) ||
        !expandedFolderGroups.includes(item.folderId) ||
        selectedItemIndex.subRow === -1)
    );
  };

  useEffect(() => {
    const newColumns = createColumns([...folders, ...maps]);
    setColumns(newColumns);
  }, [folders, maps]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.focus();
    }
  }, []);

  return (
    <div
      ref={containerRef}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      style={{ outline: 'none' }}
    >
      {!folders.length && !maps.length && !structureLoading ? (
        <div className="pt-3 text-xs text-center w-full px-2">
          Empty Quick Access Items
        </div>
      ) : (
        <>
          {structureLoading ? (
            <div className="flex flex-wrap gap-3 max-w-[400px] w-full pt-2">
              {[...Array(10)].map((_, idx) => {
                return (
                  <GeneralSkeleton
                    key={idx}
                    height={27}
                    style={{ borderRadius: '8px', width: '190px' }}
                  />
                );
              })}
            </div>
          ) : (
            <div
              className="flex"
              style={{
                gap: 24,
                maxHeight: '75dvh',
                height: '100%',
                width: 'fit-content',
              }}
              id="quick-access-modal"
            >
              {columns.map((column, columnIndex) => (
                <div
                  id={`column-${columnIndex}`}
                  style={{ flex: 1 }}
                  className="flex flex-col"
                  key={columnIndex}
                >
                  {column.map((item, rowIndex) => (
                    <div
                      key={`${rowIndex}_${columnIndex}`}
                      data-column={`item-in-column-${columnIndex}`}
                    >
                      {'folderId' in item ? (
                        <QuickAccessFolderItem
                          folder={item}
                          level={0}
                          expandedFolderGroups={expandedFolderGroups}
                          setExpandedFolderGroups={setExpandedFolderGroups}
                          isQuickAccessModal={true}
                          onSelect={handleItemSelection}
                          isHighlighted={shouldHighlightItem(
                            columnIndex,
                            rowIndex
                          )}
                          forceShowChildren={expandedFolderGroups.includes(
                            item.folderId
                          )}
                          isSelected={isSelected}
                          columnIndex={columnIndex}
                          rowIndex={rowIndex}
                          setSelectedItemIndex={setSelectedItemIndex}
                        />
                      ) : (
                        <QuickAccessMapItem
                          map={item}
                          handleClick={() => {
                            navigate(`/map/${item.mapId}`);
                            toggleQuickAccessModal();
                          }}
                          onSelect={handleItemSelection}
                          isQuickAccessModal={true}
                          isHighlighted={isSelected(columnIndex, rowIndex)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
