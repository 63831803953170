import { ResizeCallback } from 're-resizable';
import getSelectedIDs from 'utils/helpers/Selection/selectionUtils';
import { updateItemOrAreaStyle } from 'utils/stores/mapStore';
import selectedContentsStore from 'utils/stores/Selection/selectedContentStore';
import { AreaData, ItemData, PreviewData, Style } from 'utils/stores/types';

type SelectedNodes = AreaData | ItemData | PreviewData;

type ResizeNodes = {
  node: HTMLElement;
  ID: string;
  minWidth: number;
  type: ItemData['type'];
  initialDimention: {
    height: number;
    width: number;
  };
};

export function resizeAllSelected(
  clearCache: boolean,
  ID: string,
  mapID: string,
  zoomLevel: number,
  type: ItemData['type'],

  ...args: Parameters<ResizeCallback>
) {
  if (selectedContentsStore.getLength() < 2) {
    return;
  }
  const [event, direction, elementRef, delta] = args;

  if (clearCache === true) {
    if (resizeAllSelected.cacheNodes.nodes) {
      (resizeAllSelected.cacheNodes.nodes as ResizeNodes[]).forEach(item => {
        updateItemOrAreaStyle(
          item.ID,
          { customWidth: item.node.clientWidth, wrap: false },
          mapID
        );

        item.node.style.width = 'inherit';
        item.node.style.height = '100%';
      });
    }
    resizeAllSelected.cacheNodes.nodes = null;
    return;
  }

  let nodes: ResizeNodes[];

  if (resizeAllSelected.cacheNodes.nodes) {
    nodes = resizeAllSelected.cacheNodes.nodes;
  } else {
    const IDS = getSelectedIDs(ID);
    nodes = new Array(IDS.length);
    IDS.forEach((ID, index) => {
      const node = document.querySelector(
        `[data-resizable="${ID}"]`
      ) as HTMLElement;
      if (node) {
        const type = node.getAttribute(
          'data-elementType'
        ) as ResizeNodes['type'];
        const minWidth = Number(node.getAttribute('data-minWidth'));
        nodes[index] = {
          ID,
          minWidth,

          type,
          initialDimention: {
            height: node.clientHeight,
            width: node.clientWidth,
          },
          node,
        };
      }
    });

    resizeAllSelected.cacheNodes.nodes = nodes;
  }

  if (nodes) {
    nodes.forEach(node => {
      const newWidth =
        node.initialDimention.width + delta.width > node.minWidth
          ? node.initialDimention.width + delta.width
          : node.minWidth;
      node.node.style.width = `${newWidth}px`;
      if (node.type === 'Image') {
        // node.node.style.height = `${`${node.initialDimention.height + delta.height}px`}`;
      }
    });
  }
}

resizeAllSelected.cacheNodes = {
  nodes: null,
};
