import { proxy, subscribe } from 'valtio';
import { storage } from 'utils/storage';
import {
  AreaData,
  ConnectionData,
  CurrentMapStateStore,
  MapContentData,
  PendingChange,
  PendingChanges,
  PreviewData,
  StackData,
} from 'utils/stores/types';

const STORAGE_KEY = 'pendingChanges';

const pendingChangesInitialState: PendingChanges = {
  stacks: {},
  areas: {},
  connections: {},
  previews: {},
};

const loadInitialState = (): PendingChanges => {
  const storedData = localStorage.getItem(STORAGE_KEY);
  return storedData ? JSON.parse(storedData) : pendingChangesInitialState;
};

export const pendingChanges = proxy<PendingChanges>(loadInitialState());

subscribe(pendingChanges, () => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(pendingChanges));
});

export async function resetPendingChanges() {
  Object.keys(pendingChanges).forEach(key => {
    pendingChanges[key as keyof PendingChanges] = {};
  });
  localStorage.removeItem(STORAGE_KEY);
}

/**
 * Saves changes to the backend.
 * @param {Object} changes - The changes to save, structured by entity type.
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
async function saveChangesToBackend(changes) {
  try {
    const response = await fetch('/api/saveChanges', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changes),
    });

    if (!response.ok) {
      throw new Error(`Failed to save changes, status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log('Save successful:', responseData);

    //  `lastSync` time and clearing the saved changes from local state will take place here @Eberechi-uche.
  } catch (error) {
    console.error('Error saving changes to backend:', error);
    throw error;
  }
}

export const isPendingChangesEmpty = (changes: PendingChanges): boolean => {
  return !(
    Object.keys(changes.areas).length ||
    Object.keys(changes.previews).length ||
    Object.keys(changes.stacks).length ||
    Object.keys(changes.connections).length
  );
};

type Data = StackData | AreaData | ConnectionData | PreviewData;
/**
 * Updates the pending changes for a specific key and ID.
 * @param key - The key of the pending changes.
 * @param id - The ID of the change.
 * @param data - The data for the change.
 * @param changeType - The type of change ('new', 'update', or 'delete').
 */
export function updatePendingChanges(
  key: keyof PendingChanges,
  id: string,
  data: Partial<Data>,
  changeType: 'set' | 'delete' | 'resolve' | 'reject'
): void {
  if (!pendingChanges[key]) {
    pendingChanges[key] = {} as any;
  }

  const changes = { ...pendingChanges[key] };

  if (id === undefined) {
    console.error('Error: id is undefined. Skipping update.');
    return;
  }

  if (!changes[id]) {
    changes[id] = [];
  }

  const existingChanges = changes[id];
  const lastChange = existingChanges[existingChanges.length - 1];

  const newChange: PendingChange<Data> = {
    changeId: id,
    data: data as Data,
    changeType,
    lastInteraction: new Date(),
  };

  console.log('existing changes: ', JSON.stringify(existingChanges, null, 2));

  if (changeType === 'delete') {
    existingChanges.push(newChange as any);
  } else if (changeType === 'set') {
    if (lastChange && lastChange.changeType === 'delete') {
      changes[id] = [newChange as any];
    } else if (lastChange && lastChange.changeType === 'set') {
      lastChange.data = { ...lastChange.data, ...data } as Data;
      lastChange.lastInteraction = new Date();
    } else {
      existingChanges.push(newChange as any);
    }
  } else if (changeType === 'resolve' || changeType === 'reject') {
    changes[id] = [newChange as any];
  }

  pendingChanges[key] = { ...changes } as any;

  console.log('Changes:', JSON.stringify(pendingChanges, null, 2));

  // Reset pending changes
  Object.keys(pendingChanges).forEach(key => {
    pendingChanges[key] = {};
  });

  console.log(
    'Pending changes reset:',
    JSON.stringify(pendingChanges, null, 2)
  );
}

/**
 * Retrieves the ID of an entity from its data.
 * @param entityData - The data of the entity.
 * @returns The ID of the entity.
 * @throws {Error} If the entity data does not contain an ID property.
 */
export const getEntityId = (
  entityData: StackData | AreaData | ConnectionData | PreviewData
): string => {
  if ('stackId' in entityData) return entityData.stackId;
  if ('areaId' in entityData) return entityData.areaId;
  if ('connectionId' in entityData) return entityData.connectionId;
  if ('previewId' in entityData) return entityData.previewId;
  throw new Error('Invalid entity data: ID property not found.');
};

/**
 * Checks if the given data is of a valid type based on the specified type.
 * @param data - The data to be checked.
 * @param type - The type to check against.
 * @returns A boolean indicating whether the data is of the specified type.
 */
export const isValidType = (
  data: any,
  type: keyof MapContentData
): data is StackData | AreaData | ConnectionData | PreviewData => {
  console.log(`Validating type for ${type}:`, data);
  if (Object.keys(data).length === 0) {
    // Empty object is valid for deletion
    return true;
  }
  switch (type) {
    case 'stacks':
      return (data as StackData).stackId !== undefined;
    case 'areas':
      return (data as AreaData).areaId !== undefined;
    case 'connections':
      return (data as ConnectionData).connectionId !== undefined;
    case 'previews':
      return (data as PreviewData).previewId !== undefined;
    default:
      console.error(`Unknown type: ${type}`);
      return false;
  }
};
